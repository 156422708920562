import { Grid, MenuItem } from "@material-ui/core";
import BackupIcon from '@mui/icons-material/Backup';
import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import PageTitle from "../../components/PageTitle/PageTitle";
import axios from '../../utils/axios';

const UndefineConverttoDefine = () => {

const [issuerList,setIssuerList] = useState([]);
const [issuerYear,setIssuerYear] = useState([]);
const [InterimList,setInterimList] = useState([]);
const [totalAmount, setTotalAmount] = useState(0);
const [undefinedAmount,setUndefinedAmount] = useState(0.0);
const [dividendType,setDividendType] = useState([]);
const [error, setError] = useState('');
const { register, reset, handleSubmit,setValue, resetField, formState: { errors },control } = useForm();
let dividendstatus= [{id:0,label:"Defined"},{id:1,label:"Undefined"},{id:2,label:"Defined Delisted"},{id:3,label:"Undefined Delisted"}] ;

const [submitValue,setSubmitValue] = useState({
issuerid:"",
inwarddividendType:"",
UndefinedAmount:undefinedAmount,
interimyear:"",
DefinedAmount:0.0,
RestUndefined:0.0,
DefineYearId:"",
csvFile:"",
totalInvestor:0,
pdfFile:"",
investorDividendType:"",
dividendstatus:0,
});

let isFormValid = submitValue.issuerid !== "" && submitValue.DefinedAmount > 0 && submitValue.csvFile!=="" && submitValue.inwarddividendType!=="" && submitValue.interimyear!=="";

const changeHandler = (e) =>{
  setSubmitValue( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
  })
  if(e.target.name === 'DefinedAmount')
  {
    let restamount = parseFloat(submitValue.UndefinedAmount) -parseFloat(e.target.value)
    setSubmitValue( prevValues => {
      return { ...prevValues,RestUndefined:parseFloat(restamount),[e.target.name]: parseFloat(e.target.value)}
    })
  }
  if(e.target.name==='inwarddividendType')
  {
    getUndefinedAmountbyissuer(e.target.value);
    getDividendTypes(e.target.value);
  }
}
const submitHandler = () => {
  
  const formData = new FormData();
  
  formData.append('issuerid',submitValue.issuerid);
  formData.append('inwarddividendType',submitValue.inwarddividendType);
  formData.append('UndefinedAmount',submitValue.UndefinedAmount);
  formData.append('interimyear',submitValue.interimyear);
  formData.append('DefinedAmount',submitValue.DefinedAmount);
  formData.append('RestUndefined',submitValue.RestUndefined);
  formData.append('DefineYearId',submitValue.DefineYearId);
  formData.append('csvFile',submitValue.csvFile);
  formData.append('totalInvestor',submitValue.totalInvestor);
  formData.append('pdfFile',submitValue.pdfFile);
  formData.append('investorDividendType',submitValue.investorDividendType);
  formData.append('dividendstatus',submitValue.dividendstatus);
  if(totalAmount === submitValue.DefinedAmount)
  {
    Swal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      icon: 'question',

    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('/investor-informations/unidentified-inward-dividend-defined',formData)
        .then((res)=>{
          Swal.fire(res.data.payload.message, '', 'success')
        })
        .catch((e)=>{
          Swal.fire(e.message,'', 'error')
        })
        
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  else
  {
    
    Swal.fire({
      icon: 'danger',
      title: 'Please provide the correct Define Amount or Upload the right file in right format.',
      showConfirmButton: true,
      // timer: 3000
    })
  }

}
  const getIssuer = async () =>{
     await axios.get('./issuer/get-info')
    .then((res)=>{

      setIssuerList(res?.data?.payload);

    })
    .catch((err) =>{
      console.log(err)
    })
  }
  const getDividendTypes = (dividendtypeselected) =>{
    axios.get('/inward-dividend/get-dividend-types')
    .then((res)=>{
      let response = res.data.payload;
      let dividendlistfiltered = response.filter(item => item.dividendType === dividendtypeselected)

      setDividendType(dividendlistfiltered);
    }).catch((err) =>{
      console.log(err.response.data.message)
    })
  }
  const DividendYearInterim = () => {
    axios.get('/form-year-type/list')
    .then((res)=>{
      setInterimList(res.data.payload);
    })
  }
  const getyearlistbyissuer = (issueruuid)=>{

    
    if(issueruuid)
    axios.get(`/issuer-year-format/${issueruuid}`)
    .then((res)=>{
      // console.log(res?.data?.payload);
      // res.data.payload.push({id:0,year:"No value Selected"})
      setIssuerYear(res?.data?.payload);
      
    })
    .catch((err) =>{
      console.log(err.response.data.message)
    })
    
  }
  
  const getUndefinedAmountbyissuer = (inwarddividendType)=>{

      axios.get(`/inward-dividend/get-unidenrified-amount-by-issuer/${submitValue.issuerid}?dividend_type=${inwarddividendType}`)
      .then((res)=>{
        if(inwarddividendType === "Cash")
        {
          let cash = res?.data?.payload[0]?.totalcash;
          setUndefinedAmount(cash || 0);
          setSubmitValue( prevValues => {
            return { ...prevValues,UndefinedAmount:parseFloat(cash)}
          })
        }
        else
        {
          let stock = res?.data?.payload[0]?.totalstock;
          setUndefinedAmount(stock || 0);
          setSubmitValue( prevValues => {
            return { ...prevValues,UndefinedAmount:parseFloat(res?.data?.payload?.totalstock)}
          })
        } 
        
        
      })
      .catch((err) =>{
        console.log(err.response.data.message)
      })
  }


    
const handleFileUploadExcel = (event) => {
    const file = event.target.files[0];
    
    // Validate file type
    if (file && (file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      
      // Show Swal loading indicator

      Swal.fire({
        title: 'Please wait while we are calculating the values from the file!',
      })
      Swal.showLoading()
        
  
      setSubmitValue(prevValues => ({ ...prevValues, csvFile: file }));
  
      // Use FileReader API to process the file
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        try {
          // Parse the Excel file
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
          const sheetName = workbook.SheetNames[0]; // Get the first sheet
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          
          if (jsonData.length === 0) {
            setError('The selected sheet is empty.');
            return;
          }
  
          setError(''); // Clear any previous error
  
          // Calculate sum based on the selected dividend type
          let columnName = submitValue.inwarddividendType === "Cash" ? 'netDividend' : 'nofshare';
          const sum = jsonData.reduce((acc, row) => {
            const value = parseFloat(row[columnName]);
            return acc + (isNaN(value) ? 0 : value);
          }, 0);
  
          setTotalAmount(sum); // Set the sum
          setSubmitValue(prevValues => ({ ...prevValues, totalInvestor: jsonData.length }));
  
        } catch (err) {
          setError('There was an error reading the file. Please try again.');
        } finally {
          // Close Swal loading when processing is complete
          Swal.close();
        }
      };
  
      // Read the file as binary string
      reader.readAsBinaryString(file);
  
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'File format not supported. Use .xlsx or .csv only.',
        showConfirmButton: true,
      });
    }
  };
  
  
  const handleFileUploadPDF = (event) => {
    
    const file = event.target.files[0];
    
    if (file.type === "application/pdf" ) 
      {
        setSubmitValue( prevValues => {
          return { ...prevValues,pdfFile: file}
        }) 
    }
    else
    {
      Swal.fire({
        icon: 'warning',
        title: 'File does not support. You must use .pdf ',
        showConfirmButton: true,
        // timer: 3000
      })
      return;
    }
    
    
  };

  useEffect(() => {
   getIssuer();
   DividendYearInterim();
  }, [])
  


  return (
    <div style={{boxShadow:"2px 2px 2px 2px gray",padding:"50px",borderRadius:"5px"}}>
        <PageTitle title={"Undefine Convert to Define (Full or Partial)"}></PageTitle>

        <Grid  container spacing={2}>
          <Grid style={{width:"49%",padding:"20px"}} lg={6} md={6} sm={12} xs={12}>
          <div style={{marginBottom:"10px"}}> 
                <Controller
                      name="p_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const { onChange, value, ref } = field;
                        return (
                          <>
                            <Autocomplete
                                value={
                                  value
                                    ? issuerList.find((option) => option.id === value) ?? null
                                    : null
                                }
                                disableClearable
                                getOptionLabel={(option) => `${option.name} (${option.tradecode})`}
                                onChange={(event, data) => {
                                  onChange(data ? data.id : "");
                                  getyearlistbyissuer(data.id);
                                  setSubmitValue( prevValues => {
                                    return { ...prevValues,issuerid: data.id}
                                  })
                                }}
                                options={issuerList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    name="issuerid"
                                    label={"Select Issuer"}
                                    inputRef={ref}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                              />
                            {error ? (
                              <span style={{ color: "red" }}>{error.message}</span>
                            ) : null}
                          </>
                        );
                      }}
                    />  
                </div>
                <div style={{marginBottom:"10px"}}> 
                  
                    <Controller
                        name="inwarddividendType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        required
                        fullWidth
                        name="inwarddividendType"
                        value={submitValue.inwarddividendType}
                        label="Select Dividend Type"
                        variant="outlined"
                        className="input-field"
                        onChange={(event, data) => {
                          if(submitValue.issuerid==="")
                          {
                            Swal.fire({
                              icon: 'danger',
                              title: 'Please Select Issuer Id First.',
                              showConfirmButton: true,
                              // timer: 3000
                            })
                            return;
                          }
                          else
                          changeHandler(event);
                          
                        }}
                      >
                        <MenuItem value="Cash" >Cash</MenuItem>
                        <MenuItem value="Stock" >Stock</MenuItem>
                      </TextField>
                        )}/>  
                  </div>

                  <div style={{marginBottom:"10px"}}> 
                  <TextField id="UndefinedAmount" fullWidth label={submitValue.inwarddividendType==="Stock" ? "Undefined Stock" : "Undefined Amount"} value={undefinedAmount}/>
               </div>
               <div  style={{marginBottom:"10px"}}> 
                <TextField required fullWidth name="DefinedAmount" onChange={changeHandler} value={submitValue.DefinedAmount} id="outlined-basic" type="number" label={submitValue.inwarddividendType==="Stock" ? "Define Stock" : "Define Amount(in BDT)"} variant="outlined" />
                </div>
                <div style={{marginBottom:"10px"}}> 
                  <TextField fullWidth name="RestUndefined" id="outlined-basic" type="number" value={submitValue.RestUndefined} label={submitValue.inwarddividendType==="Stock" ? "Rest Undefined Stock" : "Rest Undefined Amount(in BDT)"} variant="outlined" />
                  </div>
                  
                  <div style={{marginBottom:"10px",textAlign:"center",border:"2px solid gray",paddingTop:"5px",borderRadius:"5px"}}> 
                          <label
                            for="pdfupload"
                            style={{ color: "black", fontWeight: "bold" }}
                            className="btn btn-warning btn-lg"
                            >Upload PDF File <BackupIcon style={{fill:'black'}} /></label>
                            <input
                            required
                              id="pdfupload"
                              name="csvFile"
                              type="file"
                              onChange={(e)=>{
                                  handleFileUploadPDF(e);
                              }}
                              style={{ display: "none",visibility:"none" }}
                            />
                            <h4>{submitValue.pdfFile.name}</h4>
              </div>

              <div style={{marginBottom:"10px"}}> 
                  
                        <Controller
                        name="interimyear"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        required
                        fullWidth
                        name="investorDividendType"
                        value={value}
                        label="Select Investor Dividend Type"
                        variant="outlined"
                        className="input-field"
                        onChange={changeHandler}
                      >
                        {dividendType?.map((option, index) => (
                          <MenuItem
                            key={option.id}
                            value={option.investorDividendType}
                            // disabled = {disabledYears.includes(option.id)}
                            style={{ fontSize: 13, fontWeight: "bold" }}
                          >
                          {option.investorDividendType} ({option.dividendType})
                          </MenuItem>
                        )
                        )}
                      </TextField>
                        )}/>  
                  </div>
          </Grid>
          <Grid style={{width:"49%",padding:"20px"}}  lg={6} md={6} sm={12} xs={12}>
            <div style={{marginBottom:"10px",textAlign:"center",border:"2px solid gray",paddingTop:"5px",borderRadius:"5px"}}> 
            <label
                          
                            for="excelupload"
                            style={{ color: "white", fontWeight: "bold" }}
                            className="btn btn-success btn-lg"
                            >Upload Excel File <BackupIcon style={{fill:'white'}} /></label>
                            <input
                            required
                              id="excelupload"
                              name="csvFile"
                              type="file"
                              onChange={(e)=>{
                                if(submitValue.inwarddividendType==="")
                                {
                                  Swal.fire({
                                    icon: 'warning',
                                    title: 'Please Select Dividend Type First.',
                                    showConfirmButton: true,
                                    // timer: 3000
                                  })
                                }
                                else
                                {
                                  handleFileUploadExcel(e);
                                }
                                
                              }}
                              style={{ display: "none",visibility:"none" }}
                            />
                            <h4>{submitValue.csvFile.name}</h4>
              </div>
              <div style={{marginBottom:"10px"}}> 
                <TextField fullWidth name="amount" id="outlined-basic" type="number" value={submitValue.totalInvestor} label="Total Investor" variant="outlined" />
                </div>
                  <div style={{marginBottom:"10px"}}> 
                  <TextField fullWidth name="totaldefined" id="outlined-basic" type="number" value={totalAmount} label={submitValue.inwarddividendType==="Stock" ? "Total Defined Stock" : "Total Defined Amount(in BDT)"} variant="outlined" />
                  </div>

                  <div style={{marginBottom:"10px"}}> 
                  
                        <Controller
                        name="dividendstatus"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        required
                        fullWidth
                        name="dividendstatus"
                        defaultValue={1}
                        value={value}
                        label="Select Dividend Status"
                        variant="outlined"
                        className="input-field"
                        onChange={changeHandler}
                      >
                        {dividendstatus?.map((option, index) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            // disabled = {disabledYears.includes(option.id)}
                            style={{ fontSize: 13, fontWeight: "bold" }}
                          >
                          {option.label}
                          </MenuItem>
                        )
                        )}
                      </TextField>
                        )}/>  
                  </div>

                  <div style={{marginBottom:"10px"}}> 
                  
                        <Controller
                        name="interimyear"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        required
                        fullWidth
                        name="interimyear"
                        value={value}
                        label="Select Interim"
                        variant="outlined"
                        className="input-field"
                        onChange={changeHandler}
                      >
                        {InterimList?.map((option, index) => (
                          <MenuItem
                            key={option.id}
                            value={option.name}
                            // disabled = {disabledYears.includes(option.id)}
                            style={{ fontSize: 13, fontWeight: "bold" }}
                          >
                          {option.description} ({option.name})
                          </MenuItem>
                        )
                        )}
                      </TextField>
                        )}/>  
                  </div>

                  <div style={{marginBottom:"10px"}}> 
                  {
                issuerYear.length > 0
                ?
            <Controller
                name="DefineYearId"
                control={control}
                render={({ field: { onChange, value } }) => (
              <TextField
                select
                required
                fullWidth
                name="DefineYearId"
                value={value}
                label="Select Year"
                variant="outlined"
                className="input-field"
                onChange={(event, newValue) => {
                  onChange(event.target.value);
                  changeHandler(event);
                }}
              >
                {issuerYear?.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.id}
                    // disabled = {disabledYears.includes(option.id)}
                    style={{ fontSize: 13, fontWeight: "bold" }}
                  >
                    {option.year}
                  </MenuItem>
                )
                )}
              </TextField>
                )}/>
              :
              // <TextField style={{color:"red",textAlign:"center"}} fullWidth label=" Issuer Name not selected / Issuer year not defined"  disabled/>
              <h4 style={{color:"red",textAlign:"center"}}>Issuer Name not selected / Issuer year not defined</h4>
              }
               </div>
               
          </Grid>
        </Grid>


            <Grid  style={{width:"80vw",justifyContent:"center"}} container spacing={1} >
              <Grid item>
              <Button
              style={{margin:'20px'}}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              position="center"
              disabled = {!isFormValid}
              onClick={submitHandler}
            >
              Submit
            </Button>
              </Grid>
            
            </Grid>
    </div>
  )
}

export default UndefineConverttoDefine;