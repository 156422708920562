import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { default as React, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import PageTitle from '../../../components/PageTitle/PageTitle';
import axios from '../../../utils/axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#A7C7E7",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const LoanFDRDeclaration = () => {
  const [GLList,setGLList]  = useState([]);
  const [installmentAmount,setInstallmentAmount] = useState(0.0);
  const [monthlyPrincipal,setMonthlyPrincipal] = useState(0.0);
  const [monthlyInterest,setMonthlyInterest] = useState(0.0);
  const [AmortizationSchedule,setAmortizationSchedule] = useState([]);
  const [LedgerList, setLedgerList] = useState([]);
  const [startValue, setStartValue] = useState('');
  const [endValue, setEndValue] = useState('');
  const [submitValue,setSubmitValue] = useState({
    group_ledger:'',
    DocStat:'',
    ledger_type:'',
    ldgAcNo:null,
    amount:0,
    terms:0,
    installmentRate:0.0,
    installmentAmount:0.0,
    start_date:"",
    end_date:"",
    remarks:"",

  })
  let calculateEnable = submitValue.amount>0 && submitValue.terms>0 && submitValue.installmentRate>0.0; 
  let isFormValid = false;
  if (submitValue.group_ledger==='LGIV_CRD')
  {
    isFormValid = submitValue.group_ledger!=='' && submitValue.ledger_type !=='' && submitValue.ldgAcNo !==null && 
                  submitValue.amount>0 && submitValue.terms>0 && submitValue.installmentRate>0.0 &&
                  submitValue.installmentAmount>0.0 &&  (submitValue.start_date!=='') && (submitValue.end_date!=='');                  
  }
  else if(submitValue.group_ledger === 'FDR')
  {
    isFormValid = submitValue.group_ledger!=='' && submitValue.ledger_type !=='' && submitValue.ldgAcNo !==null && submitValue.amount>0 &&
                  submitValue.terms>0 && submitValue.installmentRate>0 &&
                  (submitValue.start_date!=='') && (submitValue.end_date!=='');
  }
  useEffect(() => {
    
  axios.get(`/bank-ldfix/get-gl-acc-no-list`)
  .then((res)=>{
    const response = res.data.payload;
    let tempArray=[];
    response.map(item=>{
      if(item.glcode==="LGIV_CRD" || item.glcode==="FDR")
      {
        tempArray.push(item);
        return item;
      }
      return null;
    })
    setGLList(tempArray);
  })
  }, [])

  const submitHandler =() =>{
    if(isFormValid)
    axios.post('/bank-ldfix/create-loan-doc',submitValue)
    .then(res=>{
      if(res)
      {
        setAmortizationSchedule([]);
        setMonthlyInterest(0.0);
        setMonthlyPrincipal(0.0);
        setInstallmentAmount(0.0);
        setStartValue("");
        setEndValue("");
        setSubmitValue( {
            group_ledger:'',
            DocStat:'',
            ledger_type:'',
            ldgAcNo:null,
            amount:0,
            terms:0,
            installmentRate:0.0,
            installmentAmount:0.0,
            start_date:"",
            end_date:"",
            remarks:"",
          }
        )
      }
      Swal.fire({
        icon: 'success',
        title: `${submitValue.DocStat} for ${submitValue.ldgAcNo} Declared Successfully`,
        showConfirmButton: true,
        // timer: 3000
      })
    })
    .catch(err=>{
      Swal.fire({
        icon: 'warning',
        title: `${submitValue.DocStat} Declaration for ${submitValue.ldgAcNo} facing some error!`,
        showConfirmButton: true,
        // timer: 3000
      })
    })
  else
  alert("provide necessary values");
  }

  const changeHandler = (e) =>{
    setSubmitValue( prevValues => {
      return { ...prevValues,[e.target.name]: e.target.value}
    })
    
    if(e.target.name === 'group_ledger' )
    {
      setLedgerList([]);
      if(e.target.value === "LGIV_CRD")
      {
        setSubmitValue( prevValues => {
          return { ...prevValues,
            DocStat:"Loan",
            ledger_type:'',
            ldgAcNo:null,
            amount:0,
            terms:0,
            installmentRate:0.0,
            installmentAmount:0.0,
            start_date:"",
            end_date:"",
            remarks:"",
        
          }
        })
      }
      else if(e.target.value==="FDR")
      {
        setSubmitValue( prevValues => {
          return { ...prevValues,
            DocStat:"FDR",
            ledger_type:'',
            ldgAcNo:null,
            amount:0,
            terms:0,
            installmentRate:0.0,
            installmentAmount:0.0,
            start_date:"",
            end_date:"",
            remarks:"",
        
          }
        })
      }
      
      
      setAmortizationSchedule([]);
      setMonthlyInterest(0.0);
    setMonthlyPrincipal(0.0);
    setInstallmentAmount(0.0);
    setStartValue("");
    setEndValue("");
    }
    if(e.target.name === 'ledger_type' ||  e.target.name === 'ldgAcNo')
      {
        setAmortizationSchedule([]);
        setMonthlyInterest(0.0);
        setMonthlyPrincipal(0.0);
        setInstallmentAmount(0.0);
        setStartValue("");
        setEndValue("");
        setSubmitValue( prevValues => {
          return { ...prevValues,
            amount:0,
            terms:0,
            installmentRate:0.0,
            installmentAmount:0.0,
            start_date:"",
            end_date:"",
            remarks:"",
        
          }
        })
      }
  }


  const validateInteger = (e) => {
    let value = e.target.value;
    if(e.target.name === 'installmentRate' || e.target.name === 'installmentRate' || e.target.name === 'installmentAmount')
    {
      value = parseFloat(value);
    }
    else if(e.target.name === 'amount' || e.target.name === 'terms')
    {
      value=parseInt(value);
    }
    if(e.target.name==='terms' && submitValue.group_ledger === "FDR")
    {
      value = parseInt(e.target.value) * 12; 
    }
    
    if (/^\d*$/.test(value)) {
      setSubmitValue( prevValues => {
        return { ...prevValues,[e.target.name]: value}
      })
    }
  };
  const onChangeLedgerType =(e)=>{
    setAmortizationSchedule([]);
        setMonthlyInterest(0.0);
        setMonthlyPrincipal(0.0);
        setInstallmentAmount(0.0);
        setStartValue("");
        setEndValue("");
        setSubmitValue( prevValues => {
          return { ...prevValues,
            [e.target.name]: e.target.value,
            amount:0,
            terms:0,
            installmentRate:0.0,
            installmentAmount:0.0,
            start_date:"",
            end_date:"",
            remarks:"",
        
          }
        })
    axios.get(`http://localhost:5002/api/v1/bank-ldfix/get-glaccno-by-gltype?glname=${submitValue.group_ledger}&gltype=${e.target.value}&flagview=1`)
    .then((res)=>{
      setLedgerList(res.data.payload);
    })
    .catch(err=>{console.log(err)})
  }
  const onDateChange =(e)=>{
    if(e.target.name==='start_date'  && submitValue.terms<=0 && submitValue.group_ledger ==="FDR")
    {
      alert("Give input for the FDR Term");
      return;
    }
    if(e.target.name==='start_date'  && submitValue.terms<=0 && submitValue.group_ledger==='LGIV_CRD')
      {
        alert("Give input for the Loan Term");
        return;
      }

     let date = new Date(e.target.value).toLocaleDateString('en-CA');
    //  console.log(date);
     if(e.target.name==='start_date')
     {
      setStartValue(e.target.value);
     }
     if(date)
    setSubmitValue( prevValues => {
      return { ...prevValues,[e.target.name]:date}
    })
    else
    setSubmitValue( prevValues => {
      return { ...prevValues,[e.target.name]:""}
    })
    if(e.target.name==='start_date')
    {
      let endDate = calculateEndDate(e.target.value,submitValue.terms)
      let date = new Date(endDate).toLocaleDateString('en-CA');
      setSubmitValue( prevValues => {
        return { ...prevValues,end_date:date}
      })
    }
  }
  const calculateEndDate = (startDate, termMonths) => {
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + parseInt(termMonths,10));
      let finalend = formatDateToYYYYMMDD(endDate);
      setEndValue(finalend)
      return finalend;
      
    
  };
  const formatDateToYYYYMMDD = (date) => {
    return date.toISOString().split('T')[0]; // Extracts the date part (yyyy-mm-dd)
  };
  // console.log(submitValue)
  const handleCalculate = () =>{
    let amortizationSchedule = [];
    let outstandingBalance = submitValue.amount;
    let interest = (submitValue.installmentRate/100) / 12;
    let term = submitValue.terms;
    let periodicPayment = 0.0;
     
    let monthlyInterestpay = 0.0;
    let monthlyPrincipalpay = 0.0;
    
    for(let month = term; month>0;month--)
    {
        periodicPayment = ((outstandingBalance) * (interest*Math.pow(1+interest,month)) / (Math.pow(1+interest,month) -1)).toFixed(2);
        monthlyInterestpay = (interest*outstandingBalance).toFixed(2);
        monthlyPrincipalpay =(periodicPayment - monthlyInterestpay).toFixed(2);
        outstandingBalance = (outstandingBalance - monthlyPrincipalpay).toFixed(2);
        let monthtrack = term+1-month;
        amortizationSchedule.push({month:monthtrack,TotalPay:periodicPayment,monthlyPrincipal:monthlyPrincipalpay,monthlyInterest:monthlyInterestpay,outstandingBalance:outstandingBalance})
        
    }
    submitValue.installmentAmount = amortizationSchedule[0].TotalPay; 
    setMonthlyInterest(amortizationSchedule[0].monthlyInterest);
    setMonthlyPrincipal(amortizationSchedule[0].monthlyPrincipal);
    setInstallmentAmount(amortizationSchedule[0].TotalPay);
    setAmortizationSchedule(amortizationSchedule)
  }


  const columns = [
    { id: 'month', label: 'Month', minWidth: 170,align:"center" },
    { id: 'monthlyInterest', label: 'Interest', minWidth: 100,align:"center" },
    {
      id: 'monthlyPrincipal',
      label: 'Principal',
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'TotalPay',
      label: 'Total Payment',
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'outstandingBalance',
      label: 'Outstanding Balance',
      minWidth: 170,
      align: 'center',
    },
  ];

let AmortizationTable='';
const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
if(AmortizationSchedule.length>0)
  AmortizationTable=<Paper style={{width:"80vw", overflow:'hidden',marginLeft:'5px',marginRight:"10px",padding:"10px",boxShadow:"2px 2px 2px 2px gray",justifyContent:"center"}} container spacing={2} >
    <h1>Amortization Schedule</h1>
    <TableContainer component={Paper} sx={{ width: '100%',maxHeight: 440 }}>
              <Table stickyHeader  sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead style={{fontSize:"1.2rem"}}>
                  {/* <TableRow>
                    <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}>Month</StyledTableCell>
                    <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}>Interest</StyledTableCell>
                    <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}>Pricipal</StyledTableCell>
                    <StyledTableCell align="center" style={{fontSize:"1.2rem"}}>Payment</StyledTableCell>
                    <StyledTableCell align="center" style={{fontSize:"1.2rem"}}>Outstanding Balance</StyledTableCell>
                  </TableRow> */}
                  <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth,fontSize:"1.2rem" }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {/* {AmortizationSchedule.map((row) => (
                    <StyledTableRow key={row.month}>
                      <StyledTableCell align="center">{row.month}</StyledTableCell>
                      <StyledTableCell align="center">{row.monthlyInterest}</StyledTableCell>
                      <StyledTableCell align="center">{row.monthlyPrincipal}</StyledTableCell>
                      <StyledTableCell align="center">{row.TotalPay}</StyledTableCell>
                      <StyledTableCell align="center">{row.outstandingBalance}</StyledTableCell>
                      <StyledTableCell align="center" ><Button variant="outlined" color="error" onClick={()=>{handleDelete(row.trackid)}} startIcon={<DeleteIcon />}>Delete</Button></StyledTableCell>

                    </StyledTableRow>
                  ))}
                   <StyledTableRow  >
                  <StyledTableCell align="center" style={{fontSize:"1.2rem"}} colSpan={1}><strong>Total</strong></StyledTableCell>
                  <StyledTableCell align="center" ></StyledTableCell>
                  <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}><strong>{submitValue.p_details.reduce((total, row) => total + row.amount, 0).toFixed(2)}</strong></StyledTableCell>

                    </StyledTableRow>  */}
                     {AmortizationSchedule
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <StyledTableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>
                              );
                            })}
                          </StyledTableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[10,25,50,100,200,500]}
            component="div"
            count={AmortizationSchedule.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
  </Paper>
  let LoanDeclaration = '';

if(submitValue.group_ledger==='LGIV_CRD' && submitValue.ledger_type!=='' && submitValue.ldgAcNo!==null)
LoanDeclaration = 
<Paper style={{width:"80vw",marginLeft:'5px',marginRight:"10px",padding:"10px",boxShadow:"2px 2px 2px 2px gray",justifyContent:"center"}} container spacing={2} >
<h1 >Loan Declaration</h1>
<Grid
  style={{margin:"10px", textAlign:"center"}}
  container spacing ={3}
>
  <Grid item lg={4} md={4} sm={12} xs={12}> 
  <TextField style={{margin:"10px",width:"80%"}} name="amount" id="outlined-basic" type="number" value = {submitValue.amount} onChange={changeHandler} label="Loan Amount(in BDT)" variant="outlined" />
  </Grid>
  <Grid item lg={4} md={4} sm={12} xs={12}>
  <TextField  style={{margin:"10px" ,width:"80%"}} name="terms" id="outlined-basic" type="number" value = {submitValue.terms} onChange={validateInteger} label="Loan Terms (in months)" variant="outlined" />
  </Grid>
  <Grid item lg={4} md={4} sm={12} xs={12}>
  <TextField  style={{margin:"10px" ,width:"80%"}} name="installmentRate" id="outlined-basic" type="number" value = {submitValue.installmentRate} onChange={changeHandler} label="Interest Rate in (%)" variant="outlined" />
  </Grid>
  
</Grid>


<Grid
  style={{margin:"10px", textAlign:"center"}}
  container spacing={3}
>
  <Grid item lg={4} md={4} sm={12} xs={12}>
  <TextField style={{margin:"10px",width:"80%"}} name="installmentAmount" id="outlined-basic" type="number" value = {installmentAmount} onChange={changeHandler} label="Installment Amount(Monthly)" variant="outlined" />
  <br/>
  <span>Interest: &#2547;{monthlyInterest}</span> &nbsp; <span>Principal: &#2547;{monthlyPrincipal}</span> &nbsp; 
  </Grid>
  <Grid item lg={4} md={4} sm={12} xs={12}>
  <TextField style={{margin:"10px",width:"80%"}} name="start_date" id="outlined-basic" focused type="date" value = {startValue} onChange={onDateChange} label="Loan Start Date" variant="outlined" />
  </Grid>
  <Grid item lg={4} md={4} sm={12} xs={12}>
  <TextField style={{margin:"10px",width:"80%"}} name="end_date" id="outlined-basic" focused type="date"  value = {endValue} readOnly label="Loan End Date" variant="outlined" />
  </Grid>
</Grid>
<Grid
  style={{margin:"10px", textAlign:"center"}}
  container spacing = {3}
>
<Grid item lg={4} md={4} sm={12} xs={12}>
  <TextField style={{margin:"10px",width:"80%"}} name="remarks" id="outlined-basic" type="text" value = {submitValue.remarks} onChange={changeHandler} label="Remarks" variant="outlined" />
  </Grid>
</Grid>
<Grid style={{margin:"10px", textAlign:"center"}} container spacing={1}>
    
      <Grid item lg={12} md={12} sm={12} xs={12} >
      {calculateEnable 
      ? 
      <Button  
        style={{margin:"10px" ,width:"30%"}} 
        disabled={!calculateEnable} 
        name="calculate_interest" 
        onClick={handleCalculate} 
        size="large"
        variant="contained" 
        color="primary" 
        type="button">
          Calculate Installment Amount
          </Button>
        :null  
        }
        {
              isFormValid
              ?
              <Button
              style={{margin:'20px',width:"30%"}}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              position="right"
              disabled = {!isFormValid}
              onClick={submitHandler}
            >
              Submit
            </Button>
            :
            null

            }
      </Grid>
      
</Grid>

</Paper>

let FDRDeclaration = '';
if(submitValue.group_ledger==='FDR'  && submitValue.ledger_type!=='' && submitValue.ldgAcNo!==null)
  FDRDeclaration = 
<Paper style={{width:"80vw",marginLeft:'5px',marginRight:"10px",padding:"10px",boxShadow:"2px 2px 2px 2px gray",justifyContent:"center"}} container spacing={2} >
    <h1 >FDR Declaration</h1>
    <Grid container spacing={3}
      style={{margin:"10px", textAlign:"center"}}

    >
      <Grid item lg={4} md={4} sm={12} xs={12}> 
      <TextField style={{margin:"10px",width:"80%"}} name="amount" id="outlined-basic" type="number" value = {submitValue.amount} onChange={validateInteger} label="FDR Amount(in BDT)" variant="outlined" />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}> 
      <TextField style={{margin:"10px",width:"80%"}} name="terms" id="outlined-basic" type="number" value = {submitValue.terms/12} onChange={validateInteger} label="FDR Terms (in Years)" variant="outlined" />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}> 
      <TextField style={{margin:"10px",width:"80%"}} name="installmentRate" id="outlined-basic" type="number" value = {submitValue.installmentRate} onChange={changeHandler} label="Interest Rate in (%)" variant="outlined" />
      </Grid>
    </Grid>
    <Grid
    container spacing={3}
      style={{margin:"10px", textAlign:"center"}}

    >
      <Grid item lg={4} md={4} sm={12} xs={12}> 
      <TextField style={{margin:"10px",width:"80%"}} name="start_date" focused id="outlined-basic" type="date"  value = {startValue} onChange={onDateChange} label="FDR Start Date" variant="outlined" />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}> 
      <TextField style={{margin:"10px",width:"80%"}} name="end_date" focused id="outlined-basic" type="date" value = {endValue} readOnly label="FDR End Date" variant="outlined" />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}> 
      <TextField style={{margin:"10px",width:"80%"}} name="remarks" id="outlined-basic" type="text" value = {submitValue.remarks} onChange={changeHandler} label="Remarks" variant="outlined" />
      </Grid>
    </Grid>
    {
              isFormValid
              ?
              <Button
              style={{margin:'20px'}}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              position="right"
              disabled = {!isFormValid}
              onClick={submitHandler}
            >
              Submit
            </Button>
            :
            null

            }
</Paper>

  
  return (
    <div style={{textAlign:"center"}}>
        <PageTitle title={"Loan / FDR Declaration"}></PageTitle>
            <Grid  style={{width:"80vw",margin:'5px',marginRight:"10px",padding:"10px",boxShadow:"2px 2px 2px 2px gray",justifyContent:"center"}} container spacing={4}>
                <Grid item lg={3} md={3} sm={12} xs={12}> 
                    <FormControl fullWidth >
                        <InputLabel id="group_ledger" >Select Group Ledger </InputLabel>
                            <Select style={{marginBottom: '25px'}}
                                required
                                labelId="group_ledger"
                                name='group_ledger'
                                id="demo-simple-select"
                                // value={allValues.group_ledger}
                                label="group_ledger"
                                 onChange={changeHandler}
                                
                                >
                                  {
                                    GLList.map((item)=>{
                                      return <MenuItem value={item.glcode} key={item.glcode}>{item.glhdname}</MenuItem>
                                    })
                                  }
                                
                            </Select>  
                    </FormControl>   
                </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12} style={{marginRight:"10px"}}> 
             <FormControl fullWidth >
                 <InputLabel id="ledger_type" >Select Ledger Type</InputLabel>
                      {
                        submitValue.group_ledger==='FDR'
                        ?
                        <Select style={{marginBottom: '25px'}}
                         required
                         labelId="ledger_type"
                         name='ledger_type'
                         id="demo-simple-select"
                         value={submitValue.ledger_type}
                         label="ledger_type"
                           onChange={onChangeLedgerType}
                         >
                         <MenuItem value='FDR' key='FDR'>FDR</MenuItem>
                     </Select> 
                     :
                     submitValue.group_ledger==='LGIV_CRD'
                     ?
                     <Select style={{marginBottom: '25px'}}
                     required
                     labelId="ledger_type"
                     name='ledger_type'
                     id="demo-simple-select"
                     value={submitValue.ledger_type}
                     label="ledger_type"
                       onChange={onChangeLedgerType}
                     >
                     
                     <MenuItem value='EMP' key='EMP'>Employee</MenuItem>
                     <MenuItem value='BANK' key='BANK'>Bank</MenuItem>
                     <MenuItem value='DP' key='DP'>DP</MenuItem>
                 </Select> 
                 :
                 <Select style={{marginBottom: '25px'}}
                     required
                     labelId="ledger_type"
                     name='ledger_type'
                     id="demo-simple-select"
                     value={submitValue.ledger_type}
                     label="ledger_type"
                      //  onChange={onChangeLedgerType}
                     >
                 </Select> 
                  
                      }
                    
             </FormControl> <br />   
        </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth >
                           <Autocomplete 
                           value={submitValue.ldgAcNo
                            ? LedgerList.find((option) => option.ldgacno === submitValue.ldgAcNo) ?? null
                            : null}
                            name="ldgAcNo"                            
                            onChange={
                              (e,newValue)=>{
                                setAmortizationSchedule([]);
                                setMonthlyInterest(0.0);
                                setMonthlyPrincipal(0.0);
                                setInstallmentAmount(0.0);
                                setStartValue("");
                                setEndValue("");
                                setSubmitValue( prevValues => {
                                  return { ...prevValues,
                                    ldgAcNo: newValue.ldgacno,
                                    amount:0,
                                    terms:0,
                                    installmentRate:0.0,
                                    installmentAmount:0.0,
                                    start_date:"",
                                    end_date:"",
                                    remarks:"",
                                
                                  }
                                })
                              }
                            }
                            disableClearable
                            options={LedgerList}
                            getOptionLabel={(option) => `${option.ldgacno}: ${option.acname}`}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                 label="Select Ledger Name"
                                 name="ldgAcNo"
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                /> 
                   </FormControl> <br />   
               </Grid>
            </Grid>
            {LoanDeclaration}
            {FDRDeclaration}
            
            
            <br/>
              <br/>
            {AmortizationTable}
            
            
        
    </div>
  )
}

export default LoanFDRDeclaration