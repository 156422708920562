import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from "sweetalert2";
import PageTitle from '../../../components/PageTitle/PageTitle';
import axios from '../../../utils/axios';
import useStyles from "../../../utils/validations/useStyles";
import logo from './logo.png'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#A7C7E7",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
        // height:150,
        // overflowY: 'scroll',
        // overflowX: 'scroll'
      },
    },
  };

const options = [{ ldgacno: "Please select Group ledger", acname: "" },
// { tradecode: "No Data Available", name: "" },
];

const CreateVoucher = () => {
  const params = useParams();
  const pdfRef = useRef();
  const pdfRef2 = useRef();
    // const getUserId = localStorage.getItem("user-id")
    const classes = useStyles();
    let dividendContent ='' ;
    // let dividendContent2 ='' ;
    let SumOfCredit = 0
    let SumOfDebit = 0

    let currentDate = new Date().toJSON().slice(0, 10);
    const currentDate2 = new Date().toISOString().split('T')[0];
    const userName = localStorage.getItem('user-specific-name')
    const usableName = JSON.parse(userName)

    const [open, setOpen] = useState(false);
    const [glList, setGlList] = useState([])
    const [ledgerList, setLedgerList] = useState([])
    const [glName, setGlName] = useState("")
    const [employeeList, setEmployeeList] = useState([])
    const [bankList, setBankList] = useState([])
    const [branchList, setBranchList] = useState([]);
    const [dpList,setDPList] = useState([]);

    const [employeeName, setEmployeeName] = useState({})
    const [bankName, setbankName] = useState({})
    const [branchName, setBranchName] = useState("")
    const [nameofDp, setNameofDp] = useState({})

    const [voucherDetails, setVoucherDetails] = useState([])
    const [creditData, setCreditData] = useState([])
    const [creditSum, setCreditSum] = useState(0)
    const [debitData, setDebitData] = useState([])
    const [debitSum, setDebitSum] = useState(0)
    const [nextId,setNextId] = useState(1);

    const [groupLedger, setGroupLedger] = useState("")
    const [ledgertype, setLedgerType] = useState("")
    const [ledgerNumber, setLedgerNumber] = useState("")
    const [key, setKey] = useState(Date.now());
    const [ledgerName,setLedgerName ] = useState("")
    const [transactionType, setTransactionType] = useState("")
    const [transactionNote, setTransactionNote] = useState("")
    const [amount, setAmount] = useState()
    const [description, setDescription] = useState("")
    const [remarks, setRemarks] = useState("remarks")
    const [date, setDate] = useState(currentDate2);
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("user-id")))
  

    const [creditLedger, setCreditLedger] = useState('')
    const [debitLedger, setDebitLedger] = useState('')

    const [submittedResponse, setSubmittedResponse] = useState([{}])


    
    const handleOpenReport = (receivedData,tCredit,tDebit,vno) => {
        const reportWindow = window.open("", "_blank");
        if (reportWindow) {
          const reportHtml = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Attendance Report</title>
            <style>
              /* Global Styles */
              body {
                font-family: 'Arial', sans-serif;
                background-color: #f4f4f9;
                margin: 0;
                padding: 0;
              }
          
              .container {
                width: 80%;
                max-width: 1000px;
                margin: 0 auto;
                padding: 20px;
                background-color: #ffffff;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                overflow: hidden;
              }
          
              .headerLogo {
                display: flex;
                justify-content: center;         /* Centers horizontally */
                align-items: center;             /* Centers vertically */
                margin-bottom: 5px;
                height: 100px;                   /* Enlarges the container */
                width: 100%;     
                gap: 20px;                   /* Ensures it takes the full width of the parent container */
              }
            
                .headerLogo img {
                    max-width: 300px;                /* Controls maximum width of the logo image */
                    width: 100%;                     /* Ensures image is responsive */
                    height: auto;                    /* Keeps the image aspect ratio */
                }
              
              
              .headerLogo div {
                  flex: 1;                    
                  text-align: center;           
                  max-width: 300px;            
                  font-size: 1.5rem;           
                  padding: 10px;                
              }
              .underline {
                width: 100%;                
                height: 2px;                
                background-color: #000;    
              }
          
              .address {
                text-align: center;
                font-size: 1rem;
                margin: 5px 0;
                color: #555;
              }
          
              h4 {
                text-align: center;
                font-size: 2rem;
                font-weight: bold;
                color: #333;
              }
          
              h6 {
                text-align: center;
                font-size: 1.1rem;
                color: #666;
              }
              h7 {
                text-align: left;
                font-size: 1.1rem;
                color: #666;
              }
              h8 {
                text-align: right;
                font-size: 1.1rem;
                color: #666;
              }
          
              .table-container {
                margin-top: 30px;
                overflow-x: auto;

              }
              .table-container2 {

                overflow-x: auto;
                margin-bottom: 30px;
              }
          
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 10px;
              }
          
              th, td {
                padding: 12px;
                text-align: left;
                border: 1px solid #ddd;
                font-size: 1rem;
                color: #333;
              }
              
              th {
                background-color: #8d8b8a;
                color: white;
              }
          
              td {
                background-color: #fafafa;
              }
          
              tr:nth-child(even) td {
                background-color: #f1f1f1;
              }
          
              tr:hover td {
                background-color: #e0f7fa;
                background-color: #e0f7fa;
              }
          
              .footer {
                text-align: center;
                margin-top: 150px;
              }
          
              .footer i {
                font-style: italic;
                color: #888;
                margin-top: 50px;
              }
          
              .btn-download {
                background-color: #45CFDD;
                color: white;
                padding: 10px 20px;
                font-size: 1rem;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 20px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                transition: background-color 0.3s;
              }
          
              .btn-download:hover {
                background-color: #d1cecc;
              }
              .col-6 {
                float: center;
                width:30%;
            }
            </style>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.5.0-beta4/html2canvas.min.js"></script>
          </head>
          <body>
            <div class="container" id="pdfContent">
              
              <!-- Header Section -->
              <div class="headerLogo">
                <div>
                  <img src=${logo} alt="CMSF Logo">
                </div>
              </div>
            
          
              <!-- Address Section -->
              <div class="address">
                <span>Dhaka Chamber Building(3rd Floor), 65-66 Motijheel C/A, Dhaka-1000</span><br>
                <span>Tel: +880241052430, Email: claim@cmsfbd.org</span>
              </div>
              <div class="underline"></div>
          
              <!-- Report Title Section -->
              <h4>Voucher</h4>
              
              <div style="display: flex; justify-content: space-between; align-items: center; font-size: 1rem;">
              <h7 style="margin: 0;">Voucher No: ${vno}</h7>
              <h6 style="margin: 0; text-align: center; flex-grow: 1;">Transaction Type: Transfer</h6>
              <h8 style="margin: 0; text-align: right;">Voucher Date: 1-Nov-2024</h8>
            </div>
              
              
          
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>GL Ref</th> 			
                      <th>Ledger Name</th>
                      <th>Ledger No.</th>
                      <th class="col-6">Particulars</th>
                      <th>Amount</th>
                    </tr>
                    <tr><td>Debit</td><td></td><td></td><td></td><td></td><td></td></tr>
                  </thead>
                  <tbody>
                    ${Array.isArray(receivedData) && receivedData.length > 0 ? (
                      receivedData
                        .filter(row => row.transaction_type2 === 'debit') // Filter for transaction_type 'credit'
                        .map((row, index) => `
                          <tr>
                            <td>${index + 1}</td>
                            <td>${row.group_ledger2}</td>
                            <td>${row.ledger_name2}</td>
                            <td>${row.ledger_no2}</td>
                            <td>${row.description2}</td>
                            <td>${row.amount2}</td>
                          </tr>
                        `).join('')
                    ) : `
                      <tr>
                        <td colspan="6">Data not found</td>
                      </tr>
                    `}
                    <tr><td></td><td></td><td></td><td></td><td>Total</td><td>${tDebit}</td></tr>
                  </tbody>
                </table>
                
              </div>

              <div class="table-container2">
                <table>
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>GL Ref</th> 			
                      <th>Ledger Name</th>
                      <th>Ledger No.</th>
                      <th class="col-6">Particulars</th>
                      <th>Amount</th>
                    </tr>
                    <tr><td>Credit</td><td></td><td></td><td></td><td></td><td></td></tr>
                  </thead>
                  <tbody>
                    ${Array.isArray(receivedData) && receivedData.length > 0 ? (
                      receivedData
                        .filter(row => row.transaction_type2 === 'credit') // Filter for transaction_type 'credit'
                        .map((row, index) => `
                          <tr>
                            <td>${index + 1}</td>
                            <td>${row.group_ledger2}</td>
                            <td>${row.ledger_name2}</td>
                            <td>${row.ledger_no2}</td>
                            <td>${row.description2}</td>
                            <td>${row.amount2}</td>
                          </tr>
                        `).join('')
                        
                    ) : `
                      <tr>
                        <td colspan="6">Data not found</td>
                      </tr>
                    `}
                    <tr><td></td><td></td><td></td><td></td><td>Total</td><td>${tCredit}</td></tr>
                  </tbody>
                </table>
              </div>
          
              <!-- Footer Section -->
              <div class="footer">
                <div style="display: flex; justify-content: space-between; align-items: center; font-size: 1rem;">
                <h7 style="margin: 0;">${usableName}</h7>
                <h6 style="margin: 0; text-align: center; flex-grow: 1;"></h6>
                <h8 style="margin: 10; text-align: right;"></h8>
              </div>
                 
              </div>
              
             
                <div style="display: flex; justify-content: space-between; align-items: center; font-size: 1rem;">
                <h7 style="margin: 0;">Prepared By</h7>
                <h6 style="margin: 0; text-align: center; flex-grow: 1;">Checked By</h6>
                <h8 style="margin: 10; text-align: right;">Received By</h8>
              
                 
              </div>
          
        
              
            </div>
            <button class="btn-download" onclick="generatePdf2()">
                Download Voucher
              </button>
          
            <script>
    const generatePdf2 = async () => {
      const content = document.getElementById("pdfContent");
      const { jsPDF } = window.jspdf;
      const pdf = new jsPDF('p', 'mm', 'a4');
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL('image/png');

      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("Attendance_Report.pdf");
    };
  </script>
          </body>
          </html>
          `;
        //   const reportHtml=`
        //   <!DOCTYPE html>
        //   <html lang="en">
        //     <head>
        //       <meta charset="UTF-8" />
        //       <title>Report</title>
        //     </head>
        //     <body>
        //       <div id="root"></div> 
        //     </body>
        //   </html>
        // `;
         
          reportWindow.document.open();
          reportWindow.document.write(reportHtml);
          reportWindow.document.close();
        
        }
      
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
      };

    const handleClose = () => {
      setOpen(false);
      };

      const modalBody = (
        <Box style={modalStyle} bgcolor="background.paper" p={4} borderRadius={4}>
           <Typography variant="h6" gutterBottom>Edit Voucher</Typography>
           <Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}> 
                    <FormControl fullWidth >
                      <InputLabel id="ledger_type" >Transaction Type</InputLabel>
                        <Select style={{marginBottom: '25px'}}
                          required
                          labelId="transaction_type"
                          name='transaction_type'
                          id="demo-simple-select"
                          label="transaction_type"
                          value={transactionType}
                          onChange={(e)=>setTransactionType(e.target.value)}
                          >
                          <MenuItem value='debit' key='employee'>Debit</MenuItem>
                          <MenuItem value='credit' key='bank'>Credit</MenuItem>
                      </Select>  
                    </FormControl> <br /> 
              </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}> 
                    <FormControl fullWidth >
                      <InputLabel id="transaction_note" >Transaction Note</InputLabel>
                        <Select 
                          required
                          labelId="transaction_note"
                          name='transaction_note'
                          id="demo-simple-select"
                          label="transaction_note"
                          value={transactionNote}
                          onChange={(e)=>setTransactionNote(e.target.value)}
                          >
                          <MenuItem value='Deposit' key='Deposit'>Deposit</MenuItem>
                          <MenuItem value='Loan' key='Loan'>Loan</MenuItem>
                          <MenuItem value='Collection' key='Collection'>Collection</MenuItem>
                          <MenuItem value='Interest' key='Interest'>Interest</MenuItem>
                          <MenuItem value='Service Charge' key='Service Charge'>Service Charge</MenuItem>
                          <MenuItem value='FDR' key='FDR'>FDR</MenuItem>
                          
                      </Select>  
                    </FormControl> <br /> 
              </Grid>
              <br></br>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                          required
                          type="number"
                          label="Amount"
                          name='amount'
                          variant="outlined"
                          value={amount}
                          onChange={(e)=>setAmount(e.target.value)}
                        
                      />
              </Grid>
              <br></br>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.requiredAsterisk,
                        outlined: classes.root,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                        required
                        type="text"
                        label="Remarks"
                        name='remarks'
                        variant="outlined"
                        value={remarks}
                        onChange={(e)=>setRemarks(e.target.value)}
                       
                    />
              </Grid> */}
          </Grid>
          <Button style={{margin:'5px'}}
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
        </Box>
      );

      // console.log(nextId)
      // console.log(groupLedger)
      // console.log(ledgertype)
      // console.log(ledgerNumber)
      // console.log(ledgerName)
      // console.log(transactionType)
      // console.log(transactionNote)
      // console.log(amount)
      // console.log(remarks)
      // console.log(description)

      // const CreditSumCalculate = creditAmounts.reduce((accumulator, currentValue) => { 
      //   SumOfCredit =  accumulator + currentValue
      //   return SumOfCredit;
      // });
      // const DebitSumCalculate = debitAmounts.reduce((accumulator, currentValue) => { 
      //   SumOfDebit=  accumulator + currentValue 
      //   return SumOfDebit;
      // });

      const calculateCreditSum = () => {
        return voucherDetails
          .filter(item => item.transaction_type === 'credit') // Filter for credit transactions
          .reduce((accumulator, currentItem) => accumulator + Number(currentItem.amount), 0); // Sum amounts
      };
      
      // Function to calculate and return debit sum
      const calculateDebitSum = () => {
        return voucherDetails
          .filter(item => item.transaction_type === 'debit') // Filter for debit transactions
          .reduce((accumulator, currentItem) => accumulator + Number(currentItem.amount), 0); // Sum amounts
      };
    
      // useEffect to update sums when voucherDetails changes
      useEffect(() => {
        setCreditSum(calculateCreditSum());
        setDebitSum(calculateDebitSum());
      }, [voucherDetails]);

   
    const addItemHandler = () =>{ 
    
      if(groupLedger!=="" && ledgerNumber!=="" && transactionType!=="" && transactionNote!=="" && amount!=="" && remarks!=="" && description!=="")
        {

          // if (transactionType==='credit')
          //   {setCreditLedger(ledgerNumber);
          //   }
          // if (transactionType==='debit')
          //   {setDebitLedger(ledgerNumber);}

          const newItem = {
          trackid: nextId,
          group_ledger:groupLedger,
          ledger_type:ledgertype,
          ledger_no:ledgerNumber,
          ledger_name:ledgerName,
          transaction_type:transactionType,
          transaction_note:transactionNote,
          amount:JSON.parse(amount),
          remarks:remarks,
          description:description,
          t_date:date,
          entryby:userId,
          };
        
 
        setVoucherDetails(prevItems => [...prevItems, newItem]);
        Swal.fire({icon:'success',text:'Item added' })

        setGroupLedger("")
        setLedgerType("")
        setLedgerNumber("")
        setKey(Date.now());
        setLedgerName("")
        setTransactionType("")
        setTransactionNote("")
        setAmount("")
        // setRemarks("")
        setDescription("")
        
        setNextId(nextId + 1);
        }
      else {Swal.fire("Please fill all necessary fields to add transaction")}
     
    }

    // console.log(voucherDetails)
    // console.log(creditSum)
    // console.log(debitSum)

    const submitHandler = () => {
      const data = {drcr:voucherDetails}
      axios.post('/bank-ldfix/create-cvoucher-dvoucher',data)
        .then((res)=>{
           if(res.data.statusCode === 200){
            setSubmittedResponse(res.data.payload.result)
            Swal.fire({
              icon: 'success',
              title: 'Voucher created successfully',
              showConfirmButton: true,
              // timer: 1500
              confirmButtonText:'Okay, View Report'
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                handleOpenReport(res.data.payload.result,res.data.payload.totalCreditAmount,res.data.payload.totalDebitAmount,res.data.payload.result[0].vucherno)
              } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
              }
            });
            setVoucherDetails([])
            setNextId(1)
           }
        })
        .catch((err) =>{
          Swal.fire({
            icon: 'warning',
            title: 'Something went wrong! please try again',
            showConfirmButton: true,
          confirmButtonText:'Try again'})
        })
        
    }
  
console.log(submittedResponse)

    useEffect(()=>{
      if(groupLedger==='BWB'){setLedgerType('BWB')}
      if(groupLedger==='ISSUER'){setLedgerType('ISSUER')}
      // if(groupLedger==='INVESTOR'){setLedgerType('INVESTOR')}
      if(groupLedger==='IAC'){setLedgerType('IAC')}
      if(groupLedger==='FDR'){setLedgerType('FDR')}
      axios.get(`/bank-ldfix/get-glaccno-by-gltype?glname=${groupLedger}&gltype=${ledgertype}&flagview=1`)
        .then((res)=>{
          setLedgerList(res.data.payload)
        })
        .catch((err) =>{
          alert(err.response.data.message)
        })
    },[groupLedger,ledgertype])

    // const handleDelete = (trackid) => {
    //   const updatedDetailsCredit = voucherDetails.filter(item => item.trackid !== trackid);
    //   setVoucherDetails(prevState => ({
    //     ...prevState,...updatedDetailsCredit
    //   }));
    //   console.log(trackid)
    // };

    const handleDelete = (trackid) => {
      const updatedDetailsCredit = voucherDetails.filter(item => item.trackid !== trackid);
      // console.log(voucherDetails);
      // console.log(trackid);
      // console.log(updatedDetailsCredit)
      setVoucherDetails(updatedDetailsCredit);
    };
    

    useEffect(()=>{
      axios.get('/bank-ldfix/get-gl-acc-no-list')
        .then((res)=>{
          setGlList(res.data.payload)
        })
        .catch((err) =>{
          alert(err.response.data.message)
        })
        axios.get('/employee?flagview=1')
        .then((res)=>{
            setEmployeeList(res.data.payload)
        })
        .catch((err) =>{
          alert(err.response.data.message)
        })
        axios.get('/bank/get-all-bank-names')
        .then((res)=>{
            setBankList(res.data.payload);
        } )
        .catch((err)=>console.log(err));
        
        axios.get('/bank/get-all-dp-names')
        .then((res)=>{
            setDPList(res.data.payload);
        })
        .catch((err)=>console.log(err));
    },[])


    const getBranchList = (bank) =>{
        let object = {bankName:`${bank}`}
        axios.post('/bank/get-all-banks-by-name',object)
        .then((res)=>{
        // console.log(res.data.payload);
            // setTimeout(function(){
            setBranchList(res.data.payload);
            // }, 3000);
        
        } )
        .catch((err)=>{
        console.log(err)
        });
    }

    const handleEdit = (rowData) => {
      // setSelectedRow(rowData);

      // setEditedRow({
      //   ...rowData,
      //   from_date: new Date(rowData.from_date).toLocaleDateString('en-CA'), // Correctly format the date
      //   to_date: new Date(rowData.to_date).toLocaleDateString('en-CA'), // Correctly format the date
      //   leave_type: leaveTypes[rowData.leave_type], // Get the label for the leave type
      // });
      setOpen(true);
      //  console.log(new Date(rowData.from_date).toLocaleDateString('en-CA'));
      // console.log(rowData);
   
  }

    if(groupLedger === 'LGIV_CRD'){
      dividendContent = 
          <Grid item lg={2} md={2} sm={12} xs={12}> 
                    <FormControl fullWidth >
                        <InputLabel id="ledger_type" >Select Ledger Type</InputLabel>
                            <Select style={{marginBottom: '25px'}}
                                required
                                labelId="ledger_type"
                                name='ledger_type'
                                id="demo-simple-select"
                                value={ledgertype}
                                label="ledger_type"
                                onChange={(e)=>{setLedgerType(e.target.value);setLedgerNumber("");setKey(Date.now());}}
                                
                                >
                                <MenuItem value='EMP' key='Employee'>Employee</MenuItem>
                                <MenuItem value='BANK' key='Bank'>Bank</MenuItem>
                                <MenuItem value='DP' key='DP'>DP</MenuItem>
                            </Select>  
                    </FormControl> <br />   
                </Grid>

     } 

return (
    <>
        <PageTitle title={"Create Voucher"}></PageTitle>
        
            <Grid style={{marginLeft:'5px'}} container spacing={5}>

              <Grid item lg={3} md={3} sm={10} xs={10}> 
                    <FormControl fullWidth >
                        <InputLabel id="group_ledger" >Select Group Ledger </InputLabel>
                            <Select style={{marginBottom: '25px'}}
                                required
                                labelId="group_ledger"
                                name='group_ledger'
                                id="demo-simple-select"
                                 value={groupLedger}
                                label="group_ledger"
                                 onChange={(e)=>{setGroupLedger(e.target.value);setLedgerNumber("");setKey(Date.now())}}
                                
                                >
                                <MenuItem value='LGIV_CRD' key='LGIV_CRD'>Loan_Given_Crd</MenuItem>
                                <MenuItem value='BWB' key='BWB'>Balance With Bank</MenuItem>
                                <MenuItem value='ISSUER' key='ISSUER'>Issuer Account</MenuItem>
                                {/* <MenuItem value='INVESTOR' key='INVESTOR'>Investor Account</MenuItem> */}
                                <MenuItem value='IAC' key='IAC'>Income of Account</MenuItem>
                                <MenuItem value='FDR' key='FDR'>FDR</MenuItem>
                                {/* {glList.map((i)=>{
                                  <MenuItem value={i.glcode} key={i.glhdname}>{i.glhdname}</MenuItem>
                                })} */}
                                
                            </Select>  
                    </FormControl>   
              </Grid>

                {dividendContent} 
                
              {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.requiredAsterisk,
                        outlined: classes.root,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                       
                        required
                        label="Ledger Number"
                        name='ledger_number'
                        value={ledgerNumber}
                        // margin="normal"
                        variant="outlined"
                        onChange={(e)=>setLedgerNumber(e.target.value)}
                        // InputProps={{ ...params.InputProps, type: 'search' }}
                       
                    />
              </Grid> */}

              <Grid item lg={4} md={4} sm={12} xs={12}>  
                <FormControl fullWidth >           
                  <Autocomplete
                    //  value={ledgerNumber?ledgerNumber:options.ldgacno}
                    //  value={ledgerNumber}
                    key={key}
                      label="Select Ledger Name"
                      onChange={(event, newValue) => {
                         setLedgerNumber(newValue.ldgacno);
                         setLedgerName(newValue.acname);
                        // console.log(newValue)   
                      }}
                      disableClearable
                      options={ledgerList?ledgerList:options}
                      getOptionLabel={(option) =>  option.ldgacno +" : "+ option.acname }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          helperText={groupLedger!=='LGIV_CRD'?'*select group ledger first to get options':'*select group ledger and ledger type first to get options'}
                          label="Select Ledger Name"
                          // margin="normal"
                           variant="outlined"
                          // InputProps={{ ...params.InputProps, type: 'search' }}
                />
                      )}
                  />
                </FormControl>
              </Grid>
            </Grid>

              <br /> 

            <Grid style={{marginLeft:'5px',marginTop:'10px'}} container spacing={4}>
               
              <Grid item lg={12} md={12} sm={12} xs={12}><h4 style={{color:'#040273'}}>Transaction Details</h4></Grid>
      
              <Grid item lg={3} md={3} sm={10} xs={10}> 
                      <FormControl fullWidth >
                        <InputLabel id="ledger_type" >Transaction Type</InputLabel>
                          <Select style={{marginBottom: '25px'}}
                            required
                            labelId="transaction_type"
                            name='transaction_type'
                            id="demo-simple-select"
                            label="transaction_type"
                            value={transactionType}
                            onChange={(e)=>setTransactionType(e.target.value)}
                            >
                            <MenuItem value='debit' key='employee'>Debit</MenuItem>
                            <MenuItem value='credit' key='bank'>Credit</MenuItem>
                        </Select>  
                      </FormControl> <br /> 
              </Grid>
                  
              <Grid item lg={4} md={4} sm={10} xs={10}>
                    <TextField
                        fullWidth
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.requiredAsterisk,
                            outlined: classes.root,
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                          ),
                        }}
                            required
                            type="number"
                            label="Amount"
                            name='amount'
                            variant="outlined"
                            value={amount}
                            onChange={(e)=>setAmount(e.target.value)}
                          
                        />
              </Grid>
              <br></br>

              <Grid item lg={3} md={3} sm={10} xs={10}> 
                      <FormControl fullWidth >
                        <InputLabel id="transaction_note" >Transaction Note</InputLabel>
                          <Select 
                            required
                            labelId="transaction_note"
                            name='transaction_note'
                            id="demo-simple-select"
                            label="transaction_note"
                            value={transactionNote}
                            onChange={(e)=>setTransactionNote(e.target.value)}
                            >
                            <MenuItem value='Deposit' key='Deposit'>Deposit</MenuItem>
                            <MenuItem value='Loan' key='Loan'>Loan</MenuItem>
                            <MenuItem value='Collection' key='Collection'>Collection</MenuItem>
                            <MenuItem value='Interest' key='Interest'>Interest</MenuItem>
                            <MenuItem value='Service Charge' key='Service Charge'>Service Charge</MenuItem>
                            <MenuItem value='FDR' key='FDR'>FDR</MenuItem>
                            
                        </Select>  
                      </FormControl> <br /> 
              </Grid>

              {/* <Grid style={{marginLeft:'5px'}} container spacing={4}> */}
                  
                {/* <Grid item lg={3} md={3} sm={10} xs={10}>
                      <TextField
                          fullWidth
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              </InputAdornment>
                            ),
                          }}
                              required
                              type="text"
                              label="Remarks"
                              name='remarks'
                              variant="outlined"
                              value={remarks}
                              onChange={(e)=>setRemarks(e.target.value)}
                            
                          />
                </Grid> */}

                <Grid item lg={6} md={6} sm={10} xs={10}>
                      <TextField
                          fullWidth
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              </InputAdornment>
                            ),
                          }}
                              required
                              type="text"
                              label="Description"
                              name='description'
                              variant="outlined"
                              rows={2}
                              value={description}
                              onChange={(e)=>setDescription(e.target.value)}
                            
                          />
                </Grid>

           
                <Grid item lg={4} md={4} sm={12} xs={12}> 
                  <TextField
                       fullWidth
                       InputLabelProps={{
                         classes: {
                           asterisk: classes.requiredAsterisk,
                           outlined: classes.root,
                         },
                       }}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                           </InputAdornment>
                         ),
                       }}
                      inputProps={{
                        max: `${currentDate}`
                      }}
                        id="transaction_date"
                        label="Transaction Date"
                        variant="outlined"
                        required
                        type='date'
                       // margin="normal"
                        value={date}
                        defaultValue={currentDate2}
                        onChange={(e) => setDate(e.target.value)}
                  />
                </Grid> 
              
                <Button
                      style={{margin:'20px',color: "green"}}
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      position="right"
                      onClick={addItemHandler}
                    >
                    <LibraryAddIcon style={{fill:'white'}}/>
                </Button>
                    
              {/* </Grid> */}
              
              
            </Grid>
            
            
            {/* <Button

            style={{margin:'20px'}}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            position="right"
            onchange={resetHandler}
            >
              Reset
            </Button> */}
            <Grid item  lg={12} md={12} sm={12} xs={12} style={{marginLeft:'50px',marginTop:'60px'}} > 
            <Grid item lg={12} md={12} sm={12} xs={12}><h4 style={{color:'#040273'}}>Credit Items</h4></Grid>
              <TableContainer component={Paper} sx={{ width: '93%', align: 'center'}}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                  <TableHead >
                    <TableRow>
                    <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Sl</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Group Ledger</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Ledger Type</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Ledger No.</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Ledger Name</StyledTableCell>
                      {/* <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Transaction Type</StyledTableCell> */}
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>T_Date</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Transaction Note</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Amount</StyledTableCell>
                      {/* <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Remarks</StyledTableCell> */}
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>T_Des</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Action</StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {Array.isArray(voucherDetails) && voucherDetails.length > 0 ? (
                      voucherDetails
                        .filter(row => row.transaction_type === 'credit') // Filter for transaction_type 'credit'
                        .map((row) => (
                          <StyledTableRow key={row.trackid}>
                            <StyledTableCell align="center">{row.trackid}</StyledTableCell>
                            <StyledTableCell align="center">{row.group_ledger}</StyledTableCell>
                            <StyledTableCell align="center">{row.ledger_type}</StyledTableCell>
                            <StyledTableCell align="center">{`${row.ledger_no}`}</StyledTableCell>
                            <StyledTableCell align="center">{row.ledger_name}</StyledTableCell>
                            {/* <StyledTableCell align="center">{row.transaction_type}</StyledTableCell> */}
                            <StyledTableCell align="center">{row.t_date}</StyledTableCell>
                            <StyledTableCell align="center">{row.transaction_note}</StyledTableCell>
                            <StyledTableCell align="center">{row.amount}</StyledTableCell>
                            {/* <StyledTableCell align="center">{row.remarks}</StyledTableCell> */}
                            <StyledTableCell align="center">{row.description}</StyledTableCell>
                            <StyledTableCell align="center" >
                          <Button variant="standard" color="error" 
                             onClick={()=>{handleDelete(row.trackid)}} 
                              startIcon={<DeleteIcon />}>
                              
                          </Button>
                          </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={12} align="center">No credit transactions available</StyledTableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow >
                      <StyledTableCell align="center" style={{fontSize:"1rem"}} colSpan={7}><strong> Total Credit</strong></StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}><strong>{creditSum.toFixed(2)}</strong></StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <br></br>  <br></br>  <br></br>

            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginLeft:'50px'}}> 
            <Grid item lg={12} md={12} sm={12} xs={12}><h4 style={{color:'#040273'}}>Debit Items</h4></Grid>
              <TableContainer component={Paper} sx={{ width: '93%', align: 'center'}}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                  <TableHead >
                    <TableRow>
                      <StyledTableCell align="center" colSpan={1} style={{fontSize:"1rem"}}>Sl</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Group Ledger</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Ledger Type</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Ledger No.</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Ledger Name</StyledTableCell>
                      {/* <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Transaction Type</StyledTableCell> */}
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>T_Date</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Transaction Note</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Amount</StyledTableCell>
                      {/* <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Remarks</StyledTableCell> */}
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>T_Des</StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1rem"}}>Action</StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {Array.isArray(voucherDetails) && voucherDetails.length > 0 ? (
                      voucherDetails
                        .filter(row => row.transaction_type === 'debit') // Filter for transaction_type 'credit'
                        .map((row) => (
                          <StyledTableRow key={row.trackid}>
                            <StyledTableCell align="center">{row.trackid}</StyledTableCell>
                            <StyledTableCell align="center">{row.group_ledger}</StyledTableCell>
                            <StyledTableCell align="center">{row.ledger_type}</StyledTableCell>
                            <StyledTableCell align="center">{row.ledger_no}</StyledTableCell>
                            {/* <StyledTableCell align="center">{`${row.ledger_no}: ${row.ledger_name}`}</StyledTableCell> */}
                            <StyledTableCell align="center">{row.ledger_name}</StyledTableCell>
                            {/* <StyledTableCell align="center">{row.transaction_type}</StyledTableCell> */}
                            <StyledTableCell align="center">{row.t_date}</StyledTableCell>
                            <StyledTableCell align="center">{row.transaction_note}</StyledTableCell>
                            <StyledTableCell align="center">{row.amount}</StyledTableCell>
                            {/* <StyledTableCell align="center">{row.remarks}</StyledTableCell> */}
                            <StyledTableCell align="center">{row.description}</StyledTableCell>
                            <StyledTableCell align="center" >
                          <Button variant="standard" color="error" 
                             onClick={()=>{handleDelete(row.trackid)}} 
                              startIcon={<DeleteIcon />}>
                              
                          </Button>
                          </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={12} align="center">No debit transactions available</StyledTableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow  >
                      <StyledTableCell align="center" style={{fontSize:"1rem"}} colSpan={7}><strong> Total Debit</strong></StyledTableCell>
                      <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}><strong>{debitSum.toFixed(2)}</strong></StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br></br><br></br>
            </Grid>
            <p style={{fontSize:"1rem",marginLeft:'15px',color: 'blue'}}>*Please note that Total Credit must be Equal to Total Debit</p>
            <Grid style={{marginLeft:'5px',marginTop:'10px'}} container spacing={4}>
                 

                <Grid item lg={5} md={5} sm={12} xs={12}>
                <Button
                      style={{margin:'20px',color: "white"}}
                      disabled = {!(creditSum===debitSum)|| creditSum === 0 || debitSum === 0}
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      position="right"
                      onClick={submitHandler}
                    >
                    Save
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenReport}
                  
                >
      Open Report
    </Button> */}

              
                </Grid>
              </Grid>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="edit-modal-title"
              aria-describedby="edit-modal-description"
            >
            {modalBody}
            </Modal>
            
    </>
  )
}

export default CreateVoucher