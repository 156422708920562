
import { Box, Grid, MenuItem, Paper } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SendIcon from '@mui/icons-material/Send';
import { Autocomplete, Button, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { Triangle } from 'react-loader-spinner';
import axios from '../../utils/axios';

import { useMediaQuery, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import LoaderBar from "../../components/LoaderBar/LoaderBar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Notification from '../login/Notification';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#A7C7E7",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DividendSettlement = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let SubmissionValue = 
  {
    p_id: "",
    p_name: "",
    p_boid: "",
    p_folionumber: "",
    p_nid: "",
    p_passport: "",
    p_accounttype: 2,
    p_sholdername: "",
    p_sholdernid: "",
    p_sholderpassport: "",
    p_settlementdate: "",
    p_totalamount: "",
    p_createdby: "",
    p_updatedby: "",
    p_createdat: "",
    p_updatedat: "",
    p_status: 1,
    p_investordividendtypeid: 1,
    p_applicationdate:"",
    p_claimrecieveddate:"",
    p_settlementserialid:0,
    p_settlementstatus:0,
    p_bank_id:"",
    p_account_no:"",
    p_details:[],
    p_routingno:"",
    p_dp_id:0,
    p_stock_unit_price:0.0,
    isSuspense:0,
    p_dividendstatus:0
  }
  const { register, reset, handleSubmit,setValue, resetField, formState: { errors },control } = useForm();

  const onSubmit = data => {
    
    // const token = localStorage.getItem("bearer-token");
    // var decoded = jwtDecode(token)
    // console.log(decoded)
    // console.log(data)
   

    setSubmitValue(prevState => ({
      ...prevState,
      p_details: []
    }));
    setIsLoading(true);
    if(submitValue.p_details.length>0)
    {
      
      let totalAmount = 0;
      submitValue.p_details.map((amount)=>{
        totalAmount += amount.amount 
      })
      // data = {...data,p_details:submitValue.p_details,p_status:1,p_totalAmount:p_totalAmount}
      let newData = []
      newData = {...submitValue,...data,p_totalamount:totalAmount}
      delete newData.p_routingNo;
      delete newData.amount;
      delete newData.yearid;


      //  console.log(newData);

      axios.post('/settlement',newData)
      .then((res)=>{
          if(res.data.statusCode===200)
          {

          setSelectedBank("0");
          setAccountTypeId(2);
          setSelectedClaimType(1);
          getIssuer();
          getDividendTypes();
           
          setBankList([]);
          setDPList([]);
          setSelectedBranch("0");
          setSelectedHouse(0);
          setIsLoading(false);
          getBankList();
          getdplist();
          DividendYearInterim();
          Swal.fire({
            icon: 'success',
            title: 'Settled Dividend has been successfully stored into INSAF',
            showConfirmButton: true,
            // timer: 3000
          })
          }
          else
          {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Some Error Occured Please Try Again!',
              showConfirmButton: true
            })
          }
      })
      
      .catch((error)=>{
        setIsLoading(false);
        console.log(error.message);
      })
    }
    else{
      // alert("Please enter the dividend year and amount")
      setIsLoading(false);
      setShowError(true);
    }
    
  };
  // console.log(errors);

let accounts = [{id:1,label:"Joint Account"},{id:2,label:"Individual Account"}] ;
let claimtypelist =[{id:1,label:"Self"},{id:2,label:"Authorized Person/Power of Attorney"},{id:3,label:"Successor/Nominee"}]; 
let settlementstatus = [{id:1,label:"Ready to settle"},{id:2,label:"Settled"}];
let dividendstatus= [{id:0,label:"Defined"},{id:1,label:"Undefined"},{id:2,label:"Defined Delisted"},{id:3,label:"Undefined Delisted"}] ;


  const [issuerList,setIssuerList] = useState([]);
  const [dividendType,setDividendType] = useState([]);
 
  const settleyearlist = {yearid:0,amount:0.0,year:""};
  const [year,setYear] = useState("");
  const [yearid,setYearId] = useState(0);
  const [amount,setAmount] = useState(0.0);
  const [submitValue, setSubmitValue] = useState(SubmissionValue);
  const [AccountTypeId, setAccountTypeId] = useState(0);
  const [issuerYear,setIssuerYear] = useState([]);
  const [disabledYears, setDisabledYears] = useState([]);
  const [settlementStatus,setSettlementStatus] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [selectedBank,setSelectedBank] = useState("0");
  const [selectedHouse,setSelectedHouse] = useState(0);
  const [selectedBranch,setSelectedBranch] = useState("0");
  const [InterimList, setInterimList] = useState([]);
  const [selectedInterimYear, setSelectedInterimYear] = useState("FYI");
  const [PrevInterimYear, setPrevInterimYear] = useState("");
  const [selectedDividendType, setSelectedDividendType] = useState("");
  const [selectedDividendTypeID, setSelectedDividendTypeID] = useState("");
  const [dpList,setDPList] = useState([]);
  const [showError, setShowError] = useState(false); // Add error state
  const [nextId,setNextId] = useState(1);
  const [selectedClaimType,setSelectedClaimType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [nameLoad,setNameLoad] = useState(false);
  const [InvestorName,setInvestorName] = useState();
  const [selectedIssuer,setSelectedIssuer] = useState("");


  


const handleAddSettledYearAmount = () =>{
  // console.log(yearid,amount);

  // const disabled = disabledYears.find(year=>year==yearid)
  if(yearid>0 && amount>0 )
  {
    // submitValue.p_details.push({issuerYeaFormatId:yearid,amount:amount,year:year,status:1,interimYear:selectedInterimYear});
    // console.log(submitValue.p_details);
    const newEntry = {
      trackid: nextId, // Assign the next unique ID
      issuerYeaFormatId: yearid,
      amount: amount,
      year: year,
      status: 1,
      interimYear: selectedInterimYear,
    };
    setSubmitValue(prevState => ({
      ...prevState,
      p_details: [...prevState.p_details, newEntry],
    }));

    // Increment the nextId for the next entry
    setNextId(nextId + 1);
 
      // setDisabledYears([...disabledYears, yearid]);

      // Clear the fields after adding
      // setYear('');
      // setYearId('');
      setAmount('');
      setValue('yearid','');
      
      setValue('amount','');
      setPrevInterimYear(selectedInterimYear);
  }
  else
  {
    // alert("Please Enter a valid year and amount.");
    setShowError(true);

    
  }
  

}


const handleDelete = (trackid) => {
  // Filter out the item with the matching trackid

  const updatedDetails = submitValue.p_details.filter(item => item.trackid !== trackid);
  // Update the state with the new array
  setSubmitValue(prevState => ({
    ...prevState,
    p_details: [...updatedDetails]
  }));
  // setYearId('');
  // setDisabledYears(disabledYears.filter(item=>item!=yearid));
};

  const handleChange = (event)=>{
   
      setSubmitValue(prevState => ({
        ...prevState,
        p_accounttype: event.target.value,
        p_sholdernid:"",
        p_sholdername:""
      }));
    
    setAccountTypeId(event.target.value);
  }
  const handleChangeSettlementStatus = (event) =>{
    setSubmitValue(prevState => ({
      ...prevState,
      p_settlementstatus:event.target.value
    }));
    setSettlementStatus(event.target.value)
  }

  const handleChangeSuspense = (event) =>{
    setSubmitValue(prevState => ({
      ...prevState,
      isSuspense:event.target.value
    }));
  }
 
  const handleYear = (issueruuid)=>{
    
    if(issueruuid)
    axios.get(`/issuer-year-format/${issueruuid}`)
    .then((res)=>{
      // console.log(res?.data?.payload);
      // res.data.payload.push({id:0,year:"No value Selected"})
      setIssuerYear(res?.data?.payload);
      
    })
    .catch((err) =>{
      console.log(err.response.data.message)
    })
    
  }
  const getIssuer = () =>{
    axios.get('./issuer/get-info')
    .then((res)=>{
      setIssuerList(res?.data?.payload);

    })
    .catch((err) =>{
      console.log(err)
    })
  }

  const getDividendTypes = () =>{
    axios.get('/inward-dividend/get-dividend-types')
    .then((res)=>{
      setDividendType(res.data.payload);
    }).catch((err) =>{
      console.log(err.response.data.message)
    })
  }
const getBankList = () =>
{
  axios.get('/bank/get-all-bank-names')
  .then((res)=>{
    // console.log(res.data.payload);
    res.data.payload.map((bank,index)=>{
      bank.label=bank.bankName
      bank.value=bank.bankName
    })

    setBankList(res.data.payload);
  } )
  .catch((err)=>console.log(err));
}
const getdplist = () =>{
  axios.get('/bank/get-all-dp-names')
  .then((res)=>{
    res.data.payload.map((dp,index)=>{
      dp.label=dp.dpName+"("+dp.dpId+")"
      dp.value=dp.id
    })
    setDPList(res.data.payload);
  })
  .catch((err)=>console.log(err));
}
const getBranchList = (bank) =>
  {
    let object = {bankName:`${bank}`}
    axios.post('/bank/get-all-banks-by-name',object)
    .then((res)=>{
      // console.log(res.data.payload);
        // setTimeout(function(){
          res.data.payload.map((branch,index)=>{
            branch.label=branch.branchName
            branch.value=branch.id
          })
          setBranchList(res.data.payload);
          // }, 3000);
      
    } )
    .catch((err)=>{
      console.log(err)
    });
  }
  const handleSearchBODetails = () =>{
    if((submitValue.p_boid.length === 16 || submitValue.p_folionumber!== "") && submitValue.p_id!=="")
    {
      setNameLoad(true);

      const searchObject = {boID:submitValue.p_boid,issuer_id:submitValue.p_id,foliono:submitValue.p_folionumber};
      axios.post('/investor-informations/search',searchObject)
      .then((res)=>{
        if(res.data.payload.length>0)
        {
          setNameLoad(false);
          let loadName = res.data.payload[0].fullName || "";
          setInvestorName(loadName)
          setSubmitValue(prevState => ({
            ...prevState,
            p_name: loadName
          }));
          
        }
        setNameLoad(false);
      })
      .catch((e)=>{
        setNameLoad(false);
        console.log(e);
      })
    }
    else
    {
      setShowWarning(true);
    }
    
  }
  const DividendYearInterim = () => {
    axios.get('/form-year-type/list')
    .then((res)=>{
      setInterimList(res.data.payload);
    })
  }

  const getOpDPList = option => {
    if (!option.id) option = dpList.find(op => op.id === option);
    return option;
  };

  useEffect(() => {
    getIssuer();
    getDividendTypes();
    getBankList();
    getdplist();
    DividendYearInterim();
  }, [])


  return (
    <React.Fragment style={{ padding: "20px", textAlign:"center", width: "80vw",margin:"10px" }}>
      <PageTitle title={"Initial Dividend Settlement Entry"}></PageTitle>
    {
      isLoading
      ?
            <LoaderBar/>
        :
      <>
    <form fullWidth onSubmit={handleSubmit(onSubmit)} >
    <Grid container spacing={2}>
    <Grid item xs={12} md={6}>

    {/* <Controller
        name="p_id"
        
        required
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            
            disableClearable
            options={issuerList}
            getOptionLabel={(option) => `${option.name} (${option.tradecode})`}
            renderInput={(params) => (
              <TextField
                {...params}
                {...register("p_id")}
                label="Select Issuer"
                variant="outlined"
                fullWidth
                required
                style={{ margin: "5px 0", width: "100%" }}
                value={selectedIssuer}
              />
            )}
            onChange={(_, data) =>{ 
              setSubmitValue(prevState => ({
                ...prevState,
                p_id: data.id
              }));
                handleYear(data.id);
                setSelectedIssuer(data.id);
               
              
            }} 
          />
        )}
      /> */}
      <Controller
                      name="p_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const { onChange, value, ref } = field;
                        return (
                          <>
                            <Autocomplete
                                value={
                                  value
                                    ? issuerList.find((option) => option.id === value) ?? null
                                    : null
                                }
                                disableClearable
                                getOptionLabel={(option) => `${option.name} (${option.tradecode})`}
                                onChange={(event, data) => {
                                  // Update form value using onChange
                                  onChange(data ? data.id : "");  // Update value for Controller

                                  // Update the state with additional fields
                                  if (data) {
                                    setSubmitValue((prevState) => ({
                                      ...prevState,
                                      p_id: data.id
                                      
                                    }));
                                    handleYear(data.id);
                                    setSelectedIssuer(data.id);
                                  } else {
                                    setSubmitValue((prevState) => ({
                                      ...prevState,
                                      p_id: ""
                                      
                                    }));
                                  }
                                }}
                                options={issuerList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select Issuer"}
                                    inputRef={ref}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                              />
                            {error ? (
                              <span style={{ color: "red" }}>{error.message}</span>
                            ) : null}
                          </>
                        );
                      }}
                    />
            {/* <TextField
                    select
                    required
                    fullWidth
                    name="p_id"
                    value={searchTerm}
                    {...register("p_id", { required: true })}
                    label="Select Issuer"
                    variant="outlined"
                    className="input-field"
                    style={{ margin: "5px 0", width: "100%" }}
                    onChange={handleYear}
                  >
                    {issuerList.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                        
                      >
                        {option.name+" ( "+option.tradecode+" )"}
                      </MenuItem>
                    ))}
                 
              </TextField>         */}
      
     <TextField variant="outlined" type='number' label="BOID (must be exactly 16 digits)" style={{ margin: "5px 0", width: "87%" }}
                  {...register("p_boid", {
                    pattern: {
                      value: /^\d{16}$/, // Regex for exactly 16 digits
                      message: "BOID must be exactly 16 digits",
                    },
                    // validate: value => value.length === 16 || "BOID must be exactly 16 digits",
                  })}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_boid: e.target.value
                    }));
                  }}
                  />
                  <Tooltip title="Search if investor Exists">
                  <Button variant="contained" color="warning"  style={{padding:"12px",width:"10%",fontSize:"30px", marginLeft:"10px",marginTop:"5px"}} onClick={handleSearchBODetails}><FaSearch/></Button>
                  </Tooltip>
                  {showWarning && (
        <Notification message={"Please enter Issuer Name and BOID(16 Digit) / Folio No"} open={true} handleClose={()=>setShowWarning(false)} color='red' />
      )}
      <TextField 
      name="p_folionumber"
      fullWidth 
      variant="outlined" 
      type="text" 
      label="Folio No" 
      style={{ margin: "5px 0", width: "100%" }}
      // onChange={(e)=>{
      //   setSubmitValue(prevState => ({
      //     ...prevState,
      //     fono: e.target.value
      //   }));
      // }}
      {...register("p_folionumber")}
      onChange={(e)=>{
        setSubmitValue(prevState => ({
          ...prevState,
          p_folionumber: e.target.value
        }));
      }}
       />
      {
        nameLoad 
        ?
        <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <Triangle
                          visible={true}
                          height="60"
                          width="60"
                          color="#0275d8"
                          textAlign="center"
                          // wrapperStyle={{ padding: 'auto auto'}}
                          ariaLabel="triangle-loading"
                          wrapperClass=""
                          />
                          </div>
                          :
                          <TextField
                          // {...register("p_name")}
                          required 
                          fullWidth variant="outlined" type="text" name="p_name" 
                            id="outlined-controlled"
                            label="Name"
                            value={InvestorName}
                            style={{ margin: "5px 0", width: "100%" }}
                            onChange={(event) => {
                              setInvestorName(event.target.value);
                              setSubmitValue(prevState => ({
                                      ...prevState,
                                      p_name: event.target.value
                                    }));
                            }}
                          />
                          // <TextField required fullWidth variant="outlined" type="text" name="p_name" 
                          // value={InvestorName}
                          //   onChange={(event) => {
                          //     setInvestorName(event.target.value);
                          //     setSubmitValue(prevState => ({
                          //       ...prevState,
                          //       p_name: event.target.value
                          //     }));
                          //   }}
                          // label="Name" style={{ margin: "5px 0", width: "100%" }}
                          
                          //   {...register("p_name", {required: true})} />

      }
      
      
      <TextField fullWidth variant="outlined" type="text" name = "p_nid" label="NID (must be 10, 13, or 17 digits)" style={{ margin: "5px 0", width: "100%" }}
        onChange={(e)=>{
          setSubmitValue(prevState => ({
            ...prevState,
            p_nid: e.target.value
          }));
        }}
      {...register("p_nid", {pattern: {
        value: /^\d{10}$|^\d{13}$|^\d{17}$/,
        message: "Must be 10, 13, or 17 digits"
      }})} />
      
      <TextField fullWidth variant="outlined" type="text" name="p_passport" label="Passport" style={{ margin: "5px 0", width: "100%" }}
      onChange={(e)=>{
        setSubmitValue(prevState => ({
          ...prevState,
          p_passport: e.target.value
        }));
      }}
      {...register("p_passport")} />
      
      {/* <Select fullWidth variant="outlined"  {...register("Dividend Type", { required: true })} style={{margin:"5px"}}>
        <MenuItem value="a">a</MenuItem>
        <MenuItem value="b">b</MenuItem>
        <MenuItem value="c">c</MenuItem>
        <MenuItem value="d">d</MenuItem>
      </Select> */}
      {/* <TextField
                    select
                    fullWidth
                    name="dividendtype"
                    {...register("dividendtype", { required: true })}
                    label="Select Dividend Type"
                    variant="outlined"
                    className="input-field"
                    style={{ margin: "5px 0", width: "100%" }}
                    onChange={(e)=>{
                      setSubmitValue(prevState => ({
                        ...prevState,
                        dividendtypeid: e.target.value
                      }));
                    }}
                  >
                    {dividendTypes.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>  
       */}
      
      {/* <TextField
                    select
                    required
                    fullWidth
                    name="p_investorDividendTypeId"
                    {...register("p_investorDividendTypeId", { required: true })}
                    label="Select Investor Dividend Type"
                    variant="outlined"
                    className="input-field"
                    style={{ margin: "5px 0", width: "100%" }}
                    // onChange={(e)=>{
                    //   setSubmitValue(prevState => ({
                    //     ...prevState,
                    //     investordividendtypeid: e.target.value
                    //   }));
                    // }}
                  >
                    {
                    
                    dividendType.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.investorDividendType + " ( " + option.dividendType+ " )"}
                      </MenuItem>
                    ))
                  }
              </TextField>   */}
              <TextField
                    select
                    fullWidth
                    required
                    name="p_claimtype"
                    defaultValue={selectedClaimType}
                    {...register("p_claimtype", { required: true })}
                    label="Select Claim Type"
                    variant="outlined"
                    className="input-field"
                    onChange={(e)=>{
                      if(e.target.value==1)
                      {
                        setSubmitValue(prevState => ({
                          ...prevState,
                          p_claimtype: e.target.value,
                          p_claimantname:"",
                          p_claimantnid:"",
                        }));
                      }
                          
                        else{
                          setSubmitValue(prevState => ({
                            ...prevState,
                            p_claimtype: e.target.value,
                          }));
                        }
                      
                      setSelectedClaimType(e.target.value)
                    }}
                    style={{ margin: "5px 0", width: "100%" }}
                  >
                    {claimtypelist.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField> 
              {
                selectedClaimType == 2
                ?
                <>
                  <TextField fullWidth variant="outlined" type="text" name="p_claimantname" label="Authorized / Power of Attorney Name"  style={{ margin: "5px 0", width: "100%" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_claimantname : e.target.value
                    }));
                  }}
                  {...register("p_claimantname")} />
                  
                  <TextField fullWidth variant="outlined" type="text" label="Authorized / Power of Attorney NID" name="p_claimantnid" style={{ margin: "5px 0", width: "100%" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_claimantnid: e.target.value
                    }));
                  }}
                  {...register("p_claimantnid", {
                    pattern:{
                      value: /^\d{10}$|^\d{13}$|^\d{17}$/,
                    message: "Must be 10, 13, or 17 digits"
                    }
                    
                  })} />
                </>
                :
                selectedClaimType == 3
                ?
                <>
                    <TextField fullWidth variant="outlined" type="text" name="p_claimantname" label="Successor / Nominee Name"  style={{ margin: "5px 0", width: "100%" }}
          onChange={(e)=>{
            setSubmitValue(prevState => ({
              ...prevState,
              p_claimantname : e.target.value
            }));
          }}
          {...register("p_claimantname")} />
          
          <TextField fullWidth variant="outlined" type="text" label="Successor / Nominee NID" name="p_claimantnid" style={{ margin: "5px 0", width: "100%" }}
          onChange={(e)=>{
            setSubmitValue(prevState => ({
              ...prevState,
              p_claimantnid: e.target.value
            }));
          }}
          {...register("p_claimantnid", {
            pattern:{
              value: /^\d{10}$|^\d{13}$|^\d{17}$/,
            message: "Must be 10, 13, or 17 digits"
            }
            
          })} />
                </>
                :
                null
              }
      <TextField
                    select
                    fullWidth
                    required
                    name="p_dividendstatus"
                    {...register("p_dividendstatus", { required: true })}
                    label="Select Dividend Status"
                    variant="outlined"
                    className="input-field"
                    defaultValue={0}
                    onChange={(e)=>{
                      setSubmitValue(prevState => ({
                        ...prevState,
                        p_dividendstatus: e.target.value
                      }));
                    }}
                    style={{ margin: "3px 0px 3px 0px" }}
                  >
                    {dividendstatus.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>
      

      
      </Grid>
      <Grid item xs={12} md={6}>
      {/* <Select fullWidth variant="outlined" required aria-label="Select Account Type"  {...register("Account Type", { required: true })} style={{margin:"5px"}}>
        <MenuItem value="a">a</MenuItem>
        <MenuItem value="b">b</MenuItem>
        <MenuItem value="c">c</MenuItem>
        <MenuItem value="d">d</MenuItem>
      </Select> */}
      <TextField
                    select
                    fullWidth
                    required
                    name="p_accounttype"
                    {...register("p_accounttype", { required: true })}
                    label="Select Account Type"
                    variant="outlined"
                    className="input-field"
                    defaultValue={2}
                    onChange={handleChange}
                    style={{ margin: "3px", width: "49%" }}
                  >
                    {accounts.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>  
              <TextField
                    select
                    fullWidth
                    required
                    name="p_settlementstatus"
                    {...register("p_settlementstatus", { required: true })}
                    label="Select Settlement Status"
                    variant="outlined"
                    className="input-field"
                    onChange={handleChangeSettlementStatus}
                    style={{ margin: "3px", width: "49%" }}
                  >
                    {settlementstatus.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField> 
      
      {
        AccountTypeId == 1
        ?
        <div>
          <TextField fullWidth variant="outlined" type="text" name="p_sholdername" label="Second Name"  style={{ margin: "5px 0", width: "100%" }}
          onChange={(e)=>{
            setSubmitValue(prevState => ({
              ...prevState,
              p_sholdername : e.target.value
            }));
          }}
          {...register("p_sholdername",{required:AccountTypeId==1?true:false})} />
          
          <TextField fullWidth variant="outlined" type="text" label="Second NID" name="p_sholdernid" style={{ margin: "5px 0", width: "100%" }}
          onChange={(e)=>{
            setSubmitValue(prevState => ({
              ...prevState,
              p_sholdernid: e.target.value
            }));
          }}
          {...register("p_sholdernid", {
            pattern:{
              value: /^\d{10}$|^\d{13}$|^\d{17}$/,
            message: "Must be 10, 13, or 17 digits"
            }
            
          })} />
          
          <TextField fullWidth variant="outlined" type="text" name="p_sholderpassport" label="Second Passport" style={{ margin: "5px 0", width: "100%" }} 
          onChange={(e)=>{
            setSubmitValue(prevState => ({
              ...prevState,
              p_sholderpassport: e.target.value
            }));
          }}
          {...register("p_sholderpassport")} />
          
          </div>
          :
          null
      }
      
      

               {
        settlementStatus == 2
        ?
        <>
        <TextField required fullWidth type="number" variant="outlined" label="Settlement Serial ID" style={{ margin: "3px", width: "49%" }}
         {...register("p_settlementserialid", {required: settlementStatus===2 ? true : false})}
         onChange={(e)=>{
          setSubmitValue(prevState => ({
            ...prevState,
            p_settlementserialid:e.target.value
          }));
        }}
                 
                  />
      
      <Controller
              name="p_settlementdate"
              control={control}
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                  <DatePicker sx={{width:"49%",margin:"3px"}}
                  required= {settlementStatus==2 ? true : false}
                  label="Settlement Date"
                  {...register("p_settlementdate", { required: settlementStatus==2 ? true : false })}
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : "";
                      setSubmitValue(prevState => ({
                      ...prevState,
                      p_settlementdate: date
                    }))
                    onChange(date);
                  
                  }
                    
                    
                      }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Settlement Date"
                        variant="outlined"
                        style={{ margin: "5px" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
            </>
            :
            null
            
} 
      

      <Controller
              name="p_applicationdate"
              control={control}
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                  <DatePicker sx={{width:"49%",margin:"3px"}}
                  label="Application Date"
                  {...register("p_applicationdate")}
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : "";
                    onChange(date);
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_applicationdate:date
                    }));
                  }
                    
                    
                      }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Application Date"
                        variant="outlined"
                        style={{ margin: "5px" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="p_claimrecieveddate"
              control={control}
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                  <DatePicker sx={{width:"49%",margin:"3px"}}
                  label="Claim Recieved Date"
                  {...register("p_claimrecieveddate")}
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : "";
                    onChange(date);
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_claimrecieveddate:date
                    }));
                  }
                      }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Claim Recieved Date"
                        variant="outlined"
                        style={{ margin: "5px" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
                  <Grid container spacing ={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12} >
                  <Controller
                      name="p_investordividendtypeid"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const { onChange, value, ref } = field;
                        return (
                          <>
                            <Autocomplete
                                value={
                                  value
                                    ? dividendType.find((option) => option.id === value) ?? null
                                    : null
                                }
                                disableClearable
                                getOptionLabel={(option) => `${option.investorDividendType} (${option.dividendType})`}
                                onChange={(event, data) => {
                                  // Update form value using onChange
                                  onChange(data ? data.id : "");  // Update value for Controller

                                  // Update the state with additional fields
                                  if (data) {
                                    setSelectedDividendType(data.dividendType);
                                    setBankList([]);
                                    setDPList([]);
                                    getBankList();
                                    getdplist();
                                    setSelectedBank("0");
                                    setSelectedBranch("0");
                                    setSelectedHouse(0);
                                    // console.log(data.id);
                                    setSubmitValue(prevState => ({
                                      ...prevState,
                                      p_investordividendtypeid: data.id,
                                      p_bankName:"",
                                      p_bank_id:"",
                                      p_routingNo:"",
                                      p_dp_id:0
                                    }));
                                  } 
                                }}
                                options={dividendType}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label={"Select Investor Dividend Type"}
                                    inputRef={ref}
                                    error={!!error}
                                    style={{margin:"4px",}}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                              />
                            {error ? (
                              <span style={{ color: "red" }}>{error.message}</span>
                            ) : null}
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}  >
                  <TextField
                    select
                    fullWidth
                    required
                    defaultValue={0}
                    label="Select Suspense / Untraceable / BEFTN Return"
                    variant="outlined"
                    className="input-field"
                    onChange={handleChangeSuspense}
                    style={{ margin: "5px 0"}}
                  >
                      <MenuItem value={1}> Yes </MenuItem>
                      <MenuItem value={0}>No</MenuItem>
              </TextField> 
                  </Grid>
                  
                </Grid>
                  
                    
            
            {/* <Controller
        name="p_investordividendtypeid"
        control={control}
        render={({ field: { onChange, value } })  => (
          <Autocomplete
            options={dividendType}
            disableClearable
            getOptionLabel={(option) => `${option.investorDividendType} (${option.dividendType})`}
            value={
              value
                ? dividendType.find((option) => option.id === value) ?? null
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                {...register("p_investordividendtypeid")}
                label="Select Investor Dividend Type"
                variant="outlined"
                fullWidth
                required
                style={{ margin: "5px 0", width: "100%" }}
                
              />
            )}
            onChange={onChange=(_, data) => {
              console.log(data)
              setSelectedDividendType(data.dividendType);
              setSelectedDividendTypeID(data.id)
              setBankList([]);
              setDPList([]);
              getBankList();
              getdplist();
              setSelectedBank("0");
              setSelectedBranch("0");
              setSelectedHouse(0);
              setSubmitValue(prevState => ({
                ...prevState,
                p_investordividendtypeid: data.id,
                p_bankName:"",
                p_bank_id:"",
                p_routingNo:"",
                p_dp_id:0
              }));
              }}
          />
        )}
      /> */}
    
          {
              selectedDividendType ==="Cash"
              ?
              <>
               <Controller
                      name="p_bankName"
                      control={control}
                      rules={{
                        required: settlementStatus === 2 ? "Please Select a BankName" : false
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const { onChange, value, ref } = field;
                        return (
                          <>
                            <Autocomplete
                                value={
                                  value
                                    ? bankList.find((option) => option.bankName === value) ?? null
                                    : null
                                }
                                getOptionLabel={(option) => option.bankName || ""}  // Use the correct key (bankName) for display
                                onChange={(event, data) => {
                                  // Update form value using onChange
                                  onChange(data ? data.bankName : "");  // Update value for Controller

                                  // Update the state with additional fields
                                  if (data) {
                                    // console.log(data);
                                    setSubmitValue((prevState) => ({
                                      ...prevState,
                                      p_bankName: data.bankName,
                                      p_bank_id: "",  // Reset other fields
                                      p_routingNo: "",
                                      p_dp_id: 0
                                    }));
                                    setSelectedBank(data.bankName);
                                    setBranchList([]);
                                    resetField("p_bank_id");
                                    getBranchList(data.bankName);
                                  } else {
                                    setSubmitValue((prevState) => ({
                                      ...prevState,
                                      p_bankName: "",
                                      p_bank_id: "",
                                      p_routingNo: "",
                                      p_dp_id: 0
                                    }));
                                    setSelectedBank("");
                                    resetField("p_bank_id");
                                  }
                                }}
                                options={bankList}
                                renderInput={(params) => (
                                  <TextField
                                  style={{margin:"5px 0"}}
                                    {...params}
                                    label={"Select a Bank"}
                                    inputRef={ref}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                              />
                            {error ? (
                              <span style={{ color: "red" }}>{error.message}</span>
                            ) : null}
                          </>
                        );
                      }}
                    />
      {
                      selectedBank !=="0" ?
                      <>
                      {
                        branchList.length > 0
                        ?
                        <Controller
                            name="p_bank_id"
                            control={control}
                            {...register("p_bank_id")}
                            rules={{
                              required: selectedBank !== "" ? "Please Select a Branch Name" : false
                            }}
                            render={({ field, fieldState: { error } }) => {
                              const { onChange, value, ref } = field;
                              return (
                                <>
                                  <Autocomplete
                                      value={
                                        value
                                          ? branchList.find((option) => option.id === value) ?? null
                                          : null
                                      }
                                      getOptionLabel={(option) => `${option.branchName}` || ""} // Use the correct key (bankName) for display
                                      onChange={(event, data) => {
                                        // Update form value using onChange
                                        onChange(data ? data.id : "");  // Update value for Controller

                                        // Update the state with additional fields
                                        if (data) {
                                          setSubmitValue((prevState) => ({
                                            ...prevState,
                                            p_bank_id: data.id,  // Reset other fields
                                            p_routingNo: data.routingNo,
                                            p_dp_id: 0
                                          }));
                                        }
                                      }}
                                      options={branchList}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={"Select a Branch"}
                                          style={{ margin: "5px 0", width: "100%" }}
                                          inputRef={ref}
                                          error={!!error}
                                          helperText={error ? error.message : ""}
                                        />
                                      )}
                                    />
                                  {error ? (
                                    <span style={{ color: "red" }}>{error.message}</span>
                                  ) : null}
                                </>
                          
                              );
                            }}
                    />
                        :
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <Triangle
                          visible={true}
                          height="60"
                          width="60"
                          color="#0275d8"
                          textAlign="center"
                          // wrapperStyle={{ padding: 'auto auto'}}
                          ariaLabel="triangle-loading"
                          wrapperClass=""
                          />
                          </div>
                      } 
                      
                    <TextField name="p_routingNo"
                  fullWidth variant="outlined" 
                  focused
                  label="Routing No" 
                  style={{ margin: "5px", width: "48%" }}
                  value={submitValue.p_routingNo}

                {...register("p_routingNo")}
                />
                <TextField name="p_account_no"
                 {...register("p_account_no")}
                  required={selectedBank !== "" || selectedBank !== null  ? true : false} 
                  fullWidth variant="outlined" 
                  label="Account No" 
                  style={{ margin: "5px", width: "48%" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_account_no:e.target.value
                    }));
                  }}
               
                />
              </>
                    :
                    null
        }
              </>
              :
              selectedDividendType ==="Stock"
              ?
                <>
                    <Controller
                      name="p_dp_id"
                      control={control}
                      rules={{
                        required: settlementStatus === 2 ? "Please Select a DP House" : false
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const { onChange, value, ref } = field;
                        return (
                          <>
                            <Autocomplete
                                value={
                                  value
                                    ? dpList.find((option) => option.id === value) ?? null
                                    : null
                                }
                                getOptionLabel={(option) => `${option.dpName} (${option.dpId})` || ""}  // Use the correct key (bankName) for display
                                onChange={(event, data) => {
                                  // Update form value using onChange
                                  onChange(data ? data.id : "");  // Update value for Controller

                                  // Update the state with additional fields
                                  if (data) {
                                    setSubmitValue((prevState) => ({
                                      ...prevState,
                                      p_bankName: "",
                                      p_bank_id: "",  // Reset other fields
                                      p_routingNo: "",
                                      p_dp_id: data.id
                                    }));
                                  } else {
                                    setSubmitValue((prevState) => ({
                                      ...prevState,
                                      p_bankName: "",
                                      p_bank_id: "",
                                      p_routingNo: "",
                                      p_dp_id: 0
                                    }));
                                  }
                                }}
                                options={dpList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select a DP House"}
                                    inputRef={ref}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                              />
                            {error ? (
                              <span style={{ color: "red" }}>{error.message}</span>
                            ) : null}
                          </>
                        );
                      }}
                    />
                     <TextField name="p_stock_unit_price"
                  required={selectedHouse >0 || selectedHouse < 0  ? true : false} 
                  fullWidth variant="outlined" 
                  label="Stock Unit Price" 
                  style={{ margin: "5px 0", width: "100%" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_stock_unit_price:e.target.value
                    }));
                  }}
                {...register("p_stock_unit_price")}
                />
                </>
                :
                null
          }

     
      
      {/* <TextareaAutosize aria-label="Remarks"  {...register("Remarks", {required: true})} label="Remarks" placeholder="Write down the remarks here..." 
      onChange={(e)=>{
        console.log(e.target.value,"date");
        setSubmitValue(prevState => ({
          ...prevState,
          remarks: e.target.value
        }));
      }}
      style={{margin:"10px", marginBottom:"15px",height:"30%",width:"100%"}} /> */}
      
              {/* {
                AccountTypeId == 2 && settlementStatus==1
                ?
                <TextField
                name="p_remarks"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={5}
                  label="Remarks"
                  style={{ margin: "5px" }}
                  {...register("p_remarks")}
                />
                :
                null
              } */}




              
      
      </Grid>
     
                
                <TextField
                name="p_remarks"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={5}
                  label="Remarks"
                  style={{ margin: "5px" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_remarks:e.target.value
                    }));
                  }}
                  {...register("p_remarks")}
                />
                
      </Grid>

      {/* <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      style={{textAlign:"center"}}
    >
      <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
      <TextField
                    select
                    fullWidth
                    name="yearid"
                    {...register("yearid", { required: true })}
                    label="Select Year"
                    variant="outlined"
                    className="input-field"
                    style={{ margin: "0 15px" }}
                    onChange={(event,newValue)=>{
                      setYearId(event.target.value);
                      setYear(newValue.props.children);
                    }}
                  >
                    {issuerYear?.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.year}
                      </MenuItem>
                    ))}
              </TextField>  
              <Controller
                      name="amount"
                      control={control}
                      rules={{ required: true, min: 0 }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="number"
                          value={value}
                          onChange={(event) => {
                            const numericValue = event.target.value === '' ? '' : Number(event.target.value);
                            onChange(numericValue);
                            setAmount(numericValue); 
                          }}
                          label="Amount"
                          style={{ margin: "0 15px" }}
                        />
                      )}
                    />
              <Button variant="contained" color="warning" style={{padding:"15px",color:"black"}} onClick={handleAddSettledYearAmount} endIcon={<LibraryAddIcon/>}>Add Year</Button>
              </Grid>
              </Grid>
    </Box> */}
    <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' }, // Full width on small screens
          }}
          noValidate
          autoComplete="off"
          style={{ textAlign: 'center',margin:"20px" }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              {
                issuerYear.length > 0
                ?
            <Controller
                name="yearid"
                control={control}
                render={({ field: { onChange, value } }) => (
              <TextField
                select
                fullWidth
                name="yearid"
                value={value}
                label="Select Year"
                variant="outlined"
                className="input-field"
                style={{ margin: "0 15px" }}
                onChange={(event, newValue) => {
                  onChange(event.target.value);
                  setYearId(event.target.value);
                  setYear(newValue.props.children);
                }}
              >
                {issuerYear?.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.id}
                    // disabled = {disabledYears.includes(option.id)}
                    style={{ fontSize: 13, fontWeight: "bold" }}
                  >
                    {option.year}
                  </MenuItem>
                )
                )}
              </TextField>
                )}/>
              :
              // <TextField style={{color:"red",textAlign:"center"}} fullWidth label=" Issuer Name not selected / Issuer year not defined"  disabled/>
              <h4 style={{color:"red",textAlign:"center"}}>Issuer Name not selected / Issuer year not defined</h4>
              }
            </Grid>

            <Grid item xs={12} md={3}>
            <Controller
                name="interim"
                control={control}
                render={({ field: { onChange, value } }) => (
              <TextField
                select
                fullWidth
                name="interim"
                value={value}
                defaultValue= {"FYI"}
                label="Select Interim Year"
                variant="outlined"
                className="input-field"
                style={{ margin: "0 15px" }}
                onChange={(event, newValue) => {
                  onChange(event.target.value);
                  setSelectedInterimYear(event.target.value);
                }}
              >
                {InterimList?.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.name}
                    style={{ fontSize: 13, fontWeight: "bold" }}
                  >
                    {option.description + ' ('+option.name+ ') '}
                  </MenuItem>
                )
                )}
              </TextField>
                )}/>
              
            </Grid>

            <Grid item xs={12} md={3}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={value}
                    onChange={(event) => {
                      const numericValue = event.target.value === '' ? '' : Number(event.target.value);
                      onChange(numericValue);
                      setAmount(numericValue);
                    }}
                    label="Cash Amount / Stock Amount"
                    style={{ margin: "0 15px" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="success"
                style={{color: "white", width: isSmallScreen ? '30%' :'50%' }}
                onClick={handleAddSettledYearAmount}
                
              >
                <LibraryAddIcon style={{fill:'white'}}/>
              </Button>
            </Grid>
          </Grid>
        </Box>
        {showError && (
        <Notification message={"Please enter the dividend year and amount"} open={true} handleClose={()=>setShowError(false)} color='red' />
      )}
        {
                submitValue.p_details.length> 0 
                ?
                <Paper style={{width:"80vw", overflow:'hidden',marginLeft:'5px',marginRight:"10px",padding:"10px",boxShadow:"2px 2px 2px 2px gray",justifyContent:"center"}} container spacing={2} >
                <TableContainer component={Paper} sx={{ width: '100%',maxHeight: 440}}>
              <Table sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead style={{fontSize:"1.2rem"}}>
                  <TableRow>
                    <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}>Year</StyledTableCell>
                    <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}>Interim</StyledTableCell>
                    <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}>Cash / Stock</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{overflow:"hidden",maxHeight:440}}>
                  {submitValue.p_details.map((row) => (
                    <StyledTableRow key={row.trackid}>
                      <StyledTableCell align="center">{row.year}</StyledTableCell>
                      <StyledTableCell align="center">{row.interimYear}</StyledTableCell>
                      <StyledTableCell align="center">{row.amount}</StyledTableCell>
                      <StyledTableCell align="center" ><Button variant="outlined" color="error" onClick={()=>{handleDelete(row.trackid)}} startIcon={<DeleteIcon />}>Delete</Button></StyledTableCell>

                    </StyledTableRow>
                  ))}
                  <StyledTableRow  >
                  <StyledTableCell align="center" style={{fontSize:"1.2rem"}} colSpan={1}><strong>Total</strong></StyledTableCell>
                  <StyledTableCell align="center" ></StyledTableCell>
                  <StyledTableCell align="center"  style={{fontSize:"1.2rem"}}><strong>{submitValue.p_details.reduce((total, row) => total + row.amount, 0).toFixed(2)}</strong></StyledTableCell>

                    </StyledTableRow>
                </TableBody>
                
              </Table>
            </TableContainer>
            </Paper>
              :
              null  
            }
                <Box  display="flex" justifyContent="center" mt={2} sx={{ width: '100%' }}>
                <Button fullWidth={isSmallScreen} type="submit" sx={{padding:"15px",width: isSmallScreen ? '80%' :'40%' }} color="primary" variant="contained" endIcon={<SendIcon />}>SAVE SETTLED DIVIDEND RECORD </Button>
              </Box>

                              
            </form>
            </>
          }

    </React.Fragment>
  );
}

export default DividendSettlement
// 
// merge with Labir - 2