import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import axios from '../../utils/axios';
import useStyles from '../../utils/validations/useStyles';
import UiOfAttendanceReport from './UiOfAttendaceReport';
import Swal from "sweetalert2";



const Attendance = () => {
    const [employeeList,setEmployeeList]= useState()
    const [reportType,setReportType]= useState()
    const [data,setData]= useState([])

    let individualeportContent=''
    let allReportContent=''

    let currentDate = new Date().toJSON().slice(0, 10);

    const [handleToLimit, setHandleToLimit] = useState("2021-01-01");
    let minimumToDate = new Date(handleToLimit).toJSON().slice(0, 10);

    const [handleFromLimit, setHandleFromLimit] = useState(new Date().toJSON().slice(0, 10));
    let minimumFromDate = new Date(handleFromLimit).toJSON().slice(0, 10);

    const [singleDate, setSingleDate] = useState(new Date().toJSON().slice(0, 10));
    // let minimumFromDate = new Date(singleDate).toJSON().slice(0, 10);

    const classes = useStyles();
    var token = localStorage.getItem('bearer-token');
    var decoded = jwtDecode(token);
    console.log(decoded);


    useEffect(()=>{
        axios.get('employee?flagview=1')
    .then((res)=>{
        console.log(res.data.payload)
       setEmployeeList(res.data.payload)
    })
    .catch((err) =>{
      alert(err.response.data.message)
    })
    },[])

    const [allValues, setAllValues] = useState({
        empId: '',
        fromDate: '',
        toDate: '',

    })
    
    const reverseDate = (date) => {
        // Split the date string and reverse its parts
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      };

         const originalDate = singleDate;
        const reversedDate = reverseDate(originalDate);
    

    const changeHandler = e => {
        
        const formatDate = (dateStr) => {
            return dateStr.split("-").reverse().join("-");
          };
        if(e.target.name==="toDate" ){
            setHandleFromLimit(e.target.value)
        }
         if(e.target.name==="fromDate" ){
            setHandleToLimit(e.target.value)  
        }
        if(e.target.name==="Date" ){
            setHandleFromLimit(e.target.value)
            setHandleToLimit(e.target.value)  
            setAllValues((prevValues) => ({
                ...prevValues,
                fromDate: e.target.value,
                toDate:e.target.value
              }));
        }
        setAllValues((prevValues) => ({
              ...prevValues,
              [e.target.name]: e.target.value,
            }));
          
       
    }

    individualeportContent= <>
    <Grid item lg={3} md={3} sm={12} xs={12}>
        <FormControl fullWidth >
            <InputLabel  id="issuer-id"> Employee ID </InputLabel>
                <Select style={{marginTop:'40px'}}
                    required
                    id="empId"
                    name="empId"
                    label="empId"
                    Value={allValues.empId}
                    //fullWidth
                    variant="standard"
                    onChange={changeHandler}
                >
                    {/* api of employee list will be given.then map that */}
                    {employeeList?.map((i)=><MenuItem value={i.empidr} key={i.empidr}>{i.emp_name}</MenuItem>)}
                </Select>
        </FormControl>
    </Grid>

    <Grid item lg={3} md={3} sm={12} xs={12}>  
        <TextField
            InputLabelProps={{
            classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
            },
            }}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
            ),
            }}
            inputProps={{
            max: `${currentDate && handleFromLimit}`,
            //  min: `${}`
            }}
            fullWidth
            id="fromDate"
            name="fromDate"
            label="From Date"
            variant="outlined"
            required
            type='date'
            margin="normal"
            // value={allValues.fromDate}
            onChange={changeHandler}
        />
    </Grid>  

    <Grid item lg={3} md={3} sm={12} xs={12}> 
        <TextField
            InputLabelProps={{
            classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
            },
            }}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
            ),
            }}
            inputProps={{
            max: `${currentDate}`,
            min: `${minimumToDate}`
            }}
            fullWidth
            id="toDate"
            name="toDate"
            label="To Date"
            variant="outlined"
            required
            type='date'
            margin="normal"
            // value={allValues.toDate}
            onChange={changeHandler}
            />
    </Grid> 
    </>

    allReportContent=<>
    <Grid item lg={3} md={3} sm={12} xs={12}>
        <FormControl fullWidth >
            <InputLabel  id="issuer-id"> Employee ID </InputLabel>
                <Select style={{marginTop:'40px'}}
                    required
                    id="empId"
                    name="empId"
                    label="empId"
                    Value={allValues.empId}
                    //fullWidth
                    variant="standard"
                    onChange={changeHandler}
                >
                    <MenuItem value='All' key='All'>All</MenuItem>
                </Select>
        </FormControl>
    </Grid>

    <Grid item lg={3} md={3} sm={12} xs={12}>  
        <TextField
            InputLabelProps={{
            classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
            },
            }}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
            ),
            }}
            inputProps={{
            max: `${currentDate}`,
            //  min: `${}`
            }}
            fullWidth
            id="fromDate"
            name="Date"
            label="Select Date"
            variant="outlined"
            required
            type='date'
            margin="normal"
            // value={allValues.fromDate}
            onChange={(e)=>setSingleDate(e.target.value)}
        />
    </Grid>  

    {/* <Grid item lg={3} md={3} sm={12} xs={12}> 
        <TextField
            InputLabelProps={{
            classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
            },
            }}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
            ),
            }}
            inputProps={{
            max: `${currentDate}`,
            min: `${minimumToDate}`
            }}
            fullWidth
            id="toDate"
            name="toDate"
            label="To Date"
            variant="outlined"
            required
            type='date'
            margin="normal"
            // value={allValues.toDate}
            onChange={changeHandler}
            />
    </Grid>  */}
    
    </>
    const searchHandler = e =>{
        if(reportType==='All')
        {
            axios.get(`employee/get-attendance-by-date/${reversedDate}`)
            .then((res)=>{
                setData(res.data.payload);
                if(!res.data.payload[0])
                Swal.fire('No Data Found For This Date')     
            })
            .catch((err) =>{
                alert(err.response.data.message)
            })
        }
        else{
            axios.post('employee/employee-attendance', allValues)
        .then((res)=>{
            setData(res.data.payload);
            // console.log(res.data.payload);
            
        })
        .catch((err) =>{
            alert(err.response.data.message)
        })
        }
        }

    const reportTypeChangeHandler = (e) => {
        setReportType(e.target.value)
        setAllValues({empId: '',fromDate: '',toDate: ''})
    }
        
console.log(allValues,'allvalues')
  return (
    <>
        <PageTitle title={"Employee Attendance Record"}></PageTitle>
            <Grid container spacing={3}  style={{justifyContent:"center"}}>

                <Grid item lg={3} md={3} sm={12} xs={12}>
                        <FormControl fullWidth >
                            <InputLabel  id="issuer-id">Select Report Type</InputLabel>
                                <Select style={{marginTop:'40px'}}
                                    required
                                    id="reportType"
                                    name="reportType"
                                    label="reportType"
                                    //Value={allValues.empId}
                                    //fullWidth
                                    variant="standard"
                                    onChange={(e)=>reportTypeChangeHandler(e)}
                                >
                    
                                <MenuItem value='All' key='All' >All</MenuItem>
                                <MenuItem value='Individual' key='Individual' >Individual</MenuItem>
                                </Select>
                        </FormControl>
                </Grid>
                {/* <Grid container spacing={3}  style={{justifyContent:"center",marginTop:'20px'}}> */}
                 {reportType==='Individual'? individualeportContent:""}
                 {reportType==='All'? allReportContent:""} 
                 {/* </Grid> */}

                <Grid item lg={1} md={2} sm={2} xs={12}>
                    <span> 
                        <Button
                        variant="contained"
                                color="primary"
                                size="medium"
                                style={{margin:"10px", marginTop:"20px"}}
                                onClick={searchHandler}
                                //  disabled={allValues.empId==="" || allValues.fromDate==="" ||  allValues.toDate===""}
                                >Search
                        </Button>
                    </span>
                </Grid>

            </Grid>
          
          <Grid  container justifyContent="center" style={{ minHeight: '100vh',marginTop:'30px' }} >
            {(data.length!==0) && <UiOfAttendanceReport data={data} stateValus={allValues} reportType={reportType}/>}</Grid>
        </>

  )
}
// hello
export default Attendance