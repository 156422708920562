import {
  Box,
  Button, Grid,
  MenuItem,
  Modal, TextField, Typography,
} from "@material-ui/core";
import {
  MaterialReactTable
} from 'material-react-table';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from './../../utils/axios';

  const LeaveApplicationTable = ({ leavedata,onEdit}) => {
  const [errors, setErrors] = useState({});  const [open, setOpen] = useState(false); // State to control modal visibility
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data
  const [editedRow, setEditedRow] = useState(null); // State for editable row data


    const leaveTypes = {
        1: 'Casual Leave',
        2: 'Sick Leave',
        3: 'Earned Leave',
        4: 'Maternity Leave',
        5: 'Early Departure',
        6: 'On Duty',
        7: 'Paternity Leave'
      };

      const calculateTotalDays = (fromDate, toDate) => {
        if (fromDate && toDate) {
          const from = new Date(fromDate);
          const to = new Date(toDate);
          const timeDiff = to - from; // Difference in milliseconds
          return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // Convert to days and add 1 to include both days
        }
        return 0; // Return 0 if either date is not set
      };


      
      const handleEdit = (rowData) => {
        setSelectedRow(rowData);
    

        setEditedRow({
          ...rowData,
          from_date: new Date(rowData.from_date).toLocaleDateString('en-CA'), // Correctly format the date
          to_date: new Date(rowData.to_date).toLocaleDateString('en-CA'), // Correctly format the date
          leave_type: leaveTypes[rowData.leave_type], // Get the label for the leave type
        });
        setOpen(true);
     
    }
    // Handle Delete action
    const handleDelete = async (id) => {
      // Show confirmation alert
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this leave application?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // If confirmed, make the delete request
          try {
            const response = await axios.delete(`/employee/delete-leave/${id}`);
            
            if (response.status === 200) {

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Leave application successfully deleted.',
                timer: 2000,
                showConfirmButton: false
              });


                // Remove the deleted item from leavedata
          const updatedData = leavedata.filter(item => item.id !== id);
          
          // Call onEdit with updated data to refresh the table
          onEdit(updatedData);
         
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to delete leave application. Please try again later.',
              });
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'An error occurred while deleting the leave application.',
            });
            console.error('Delete error:', error);
          }
        }
      });
    };
  
    // Close the modal
    const handleClose = () => {

      setOpen(false);
      setSelectedRow(null);
      setEditedRow(null); // Reset editedRow when closing
    };
  
    const handleSaveChanges = async () => {
       // Helper function to show error messages with Swal
       const showError = (title, text) => {
        Swal.fire({
          icon: 'error',
          title,
          text,
        });
        handleClose();
      };
  
      try {
        const today = new Date().toISOString().split('T')[0];
        const { leave_type, from_date, to_date, total_days, remarks } = editedRow;
    
       
        // Validation checks
        const isValid = () => {
          if (from_date > to_date) {
            showError('Invalid Date Range', '"To Date" should be equal to or later than "From Date".');
            return false;
          }
        
          if (leave_type === 'On Duty' && (from_date <= today || to_date <= today || from_date !== to_date)) {
            showError('Invalid Date', 'For "On Duty", From Date and To Date must be the same and in the future.');
            return false;
          }
          if (leave_type === 'Early Departure' && (from_date !== today || to_date !== today)) {
            showError('Invalid Date', 'For "Early Departure", From Date and To Date must be today.');
            return false;
          }
          if (leave_type !== 'Sick Leave' && from_date < today) {
            showError('Invalid Date', 'For this leave type, From Date cannot be in the past.');
            return false;
          }
          return true;
        };
    
        if (!isValid()) return; // Exit if validation fails
    
        const leaveTypeKey = Object.keys(leaveTypes).find(key => leaveTypes[key] === leave_type); // Convert leave type back to number
        // const leaveTypeKey = Number(Object.keys(leaveTypes).find(key => leaveTypes[key] === leave_type));


        // Prepare payload for API call
        const payload = {
          fromDate: from_date,
          toDate: to_date,
          leaveType: Object.keys(leaveTypes).find(key => leaveTypes[key] === leave_type),
          totalDays: total_days,
          remarks,
        };
    
        // API call to update the leave application
        const response = await axios.post(`/employee/edit-leave/${selectedRow.id}`, payload);
    
        if (response.data.success) {
          handleClose();
          Swal.fire({
            icon: 'success',
            title: 'Updated!',
            text: response.data.payload.fn_update_leave_application_by_emp,
            timer: 3000,
            showConfirmButton: true,
          });

           // Update data with the modified row and call onEdit to refresh the table
           const updatedData = leavedata.map(item =>
            item.id === selectedRow.id
              ? {  ...item, leave_type: leaveTypeKey, total_days, from_date, to_date, remarks} // Keep all properties from item, only update leave_type
              : item
          );

      onEdit(updatedData);  // Ensure this updates the parent component’s state
    } else {
      showError('Error', 'Failed to update leave application. Please try again later.');
    }
      } catch (error) {
        showError('Error', 'An error occurred while updating the leave application.');
        console.error('Edit error:', error);
      }
    };
    

const columns = [
  {
    accessorKey: 'serialNumber',
    header: 'SL',
    size: 50 ,
    Cell: ({ row }) => row.index + 1, // Dynamically generate the serial number based on the row index
},
  { accessorKey: 'emp_id', header: 'Emp Id',size: 50 },
  {
    accessorKey: 'from_date',
    header: 'From Date',
    size: 50,
    Cell: ({ cell }) => {
      const dateValue = cell.getValue();
      return dateValue 
        ? new Date(dateValue).toLocaleString('en-US', { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            
          }) 
        : 'Not yet';
    },
  },
  {
    accessorKey: 'to_date',
    header: 'To Date',
    size: 50,
    Cell: ({ cell }) => {
      const dateValue = cell.getValue();
      return dateValue 
        ? new Date(dateValue).toLocaleString('en-US', { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            
          }) 
        : 'Not yet';
    },
  },
  {
    accessorKey: 'leave_type',
    header: 'Leave Type',
    size: 50,
    Cell: ({ cell }) => leaveTypes[cell.getValue()] || 'Unknown',
  },
  { accessorKey: 'total_days', header: 'TotalDays', size: 50, },
  {
    accessorKey: 'entry_date',
    header: 'Application time & Date',
    size: 50,
    Cell: ({ cell }) => {
      const dateValue = cell.getValue();
      return dateValue 
        ? new Date(dateValue).toLocaleString('en-US', { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
            
          }) 
        : 'Not yet';
    },
  },
  { accessorKey: 'remarks', header: 'Remarks', size: 50 },


  {
    accessorKey: 'n1_status',
    header: 'Team Leader Status',
    size: 50,
    Cell: ({ cell }) => {
      const status = cell.getValue();
      let statusText = 'Unknown';
      let backgroundColor = '#f0f0f0'; // Default background
      let color = '#333'; // Default text color
  
      if (status === null || status === 0) {
        statusText = 'Pending';
        backgroundColor = '#d4d6b6'; // Yellow for Pending
        color = '#333'; // Dark text for readability
      } else if (status === 1) {
        statusText = 'Approved';
        backgroundColor = '#75a832'; // Green for Approved
        color = '#fff'; // White text for contrast
      } else if (status === 2) {
        statusText = 'Rejected';
        backgroundColor = '#f44336'; // Red for Rejected
        color = '#fff'; // White text for contrast
      }
      return (
        <div
          style={{
            backgroundColor,
            color,
            padding: '1px',
            borderRadius: '3px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {statusText}
        </div>
      );
    },
  },  

  {
    accessorKey: 'n1_entry_date',
    header: 'Team Leader Status time & Date',
    size: 50,
    Cell: ({ cell }) => {
      const dateValue = cell.getValue();
      return dateValue 
        ? new Date(dateValue).toLocaleString('en-US', { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
          }) 
        : 'Not yet';
    },
  },
  
  { accessorKey: 'n1_remarks', header: 'Team Leader Remarks', size: 50 },
  
  // Custom hr_status column
  {
    accessorKey: 'hr_status',
    header: 'HR Status',
    size: 50,
    Cell: ({ row }) => {
      const n1Status = row.getValue('n1_status'); // Access n1_status value
      const status = row.getValue('hr_status'); // Access hr_status value
  
      let statusText = 'Unknown';
      let backgroundColor = '#f0f0f0'; // Default background
      let color = '#333'; // Default text color
  
      // Check n1_status and set hr_status accordingly
      if (n1Status === 2) {
        statusText = 'Rejected by N1'; // Custom status text
        backgroundColor = '#f44336'; // Red for Rejected
        color = '#fff'; // White text for contrast
      } else {
        // Original status rendering logic
        if (status === null || status === 0) {
          statusText = 'Pending';
          backgroundColor = '#d4d6b6'; // Yellow for Pending
          color = '#333'; // Dark text for readability
        } else if (status === 1) {
          statusText = 'Approved';
          backgroundColor = '#75a832'; // Green for Approved
          color = '#fff'; // White text for contrast
        } else if (status === 2) {
          statusText = 'Rejected';
          backgroundColor = '#f44336'; // Red for Rejected
          color = '#fff'; // White text for contrast
        }
      }
  
      return (
        <div
          style={{
            backgroundColor,
            color,
            padding: '1px',
            borderRadius: '3px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {statusText}
        </div>
      );
    },
  },
  

  {
    accessorKey: 'hr_entry_date',
    header: 'HR Status time & Date',
    size: 50,
    Cell: ({ cell }) => {
      const dateValue = cell.getValue();
      return dateValue 
        ? new Date(dateValue).toLocaleString('en-US', { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
          }) 
        : 'Not yet';
    },
  },

  
  { accessorKey: 'hr_remarks', header: 'HR Remarks', size: 50 },
  { accessorKey: 'hr_entry_by', header: 'HR Entry by', size: 50 },

  {
    header: 'Actions',
    Cell: ({ row }) => {
      const isDisabled = row.original.n1_status !== null || row.original.hr_status !== null; 
    
      return (
        <div>
                <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              if (isDisabled) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Action Restricted',
                  text: 'This entry cannot be edited because its status is already set.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              } else {
                handleEdit(row.original);
              }
            }}
            style={{ marginRight: '8px', opacity: isDisabled ? 0.6 : 1 }}
          >
            Edit
          </Button>

            <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              if (isDisabled) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Action Restricted',
                  text: 'This entry cannot be deleted because its status is already set.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              } else {
                handleDelete(row.original.id);
              }
            }}
            style={{ opacity: isDisabled ? 0.8 : 1 }}
          >
            Delete
          </Button>

        </div>
      );
    },
  },
]; 
    // Define the modal styling
    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
    };
  
    // Define the modal body
    const modalBody = (
        <Box style={modalStyle} bgcolor="background.paper" p={4} borderRadius={4}>
          <Typography variant="h6" gutterBottom>Edit Leave Application</Typography>
          <TextField
            label="Employee ID"
            fullWidth
            margin="normal"
            value={selectedRow?.emp_id || ''}
            disabled
          />
          <TextField
            label="From Date"
            type="date"
            fullWidth
            margin="normal"
           
            value={editedRow?.from_date || ''}
            InputLabelProps={{ shrink: true }}

            onChange={(e) => {
              const newFromDate = e.target.value;
              const newTotalDays = calculateTotalDays(newFromDate, editedRow?.to_date); // Recalculate total days
              setEditedRow((prev) => ({
                ...prev,
                from_date: newFromDate,
                total_days: newTotalDays, // Update total_days state
              }));
            }} 
          />

          <TextField
            label="To Date"
            type="date"
            fullWidth
            margin="normal"
            value={editedRow?.to_date || ''}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const newToDate = e.target.value;
              const newTotalDays = calculateTotalDays(editedRow?.from_date, newToDate); // Recalculate total days
              setEditedRow((prev) => ({
                ...prev,
                to_date: newToDate,
                total_days: newTotalDays, // Update total_days state
              }));
            }} // Updated this line
          />
          <TextField
            select
            label="Leave Type"
            fullWidth
            margin="normal"
            value={editedRow?.leave_type || ''}
            onChange={(e) => 
            
              setEditedRow({ ...editedRow, leave_type: e.target.value })}
          >
            {Object.entries(leaveTypes).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
      
          {/* Display Total Days */}
          <TextField
            label="Total Days"
            fullWidth
            margin="normal"
            value={editedRow?.total_days || ''} // Show the total days from edited row
            disabled
          />
      
      <TextField
          label="Remarks"
          fullWidth
          margin="normal"
          value={editedRow?.remarks || ''}
          onChange={(e) => setEditedRow({ ...editedRow, remarks: e.target.value })}
        />
          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
              >
                Save Changes
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      );
      
    return (
   <>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  <MaterialReactTable 
    columns={columns} 
    data={leavedata} 
    initialState={{
      density: 'compact',
      pagination: {
        pageSize: 5, // Sets default rows per page
      },
    }}
    style={{ width: '100%' }} 
    muiTableBodyCellProps={{
      sx: {
          border: '0.5px solid #e0e0e0',  
       
      },
  }}
  muiTableHeadCellProps={{
      sx: {
          border: '0.5px solid #e0e0e0', 
      },
  }}
  />
</Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="edit-leave-modal" aria-describedby="edit-leave-form">
        {modalBody}
      </Modal>
    </>
    );
  };
  
  export default LeaveApplicationTable;
  