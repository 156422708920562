import React from "react";
import Swal from "sweetalert2";
import { forgetPassword } from "../services/AuthService";
import axios from "../utils/axios";


export var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}



function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("bearer-token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { forgetPasswordByEmail, forgetPasswordByOtp, forgetPasswordByPhoneNumber, forgetPasswordSendOtp, loginUser, loginWithOtp, registrationUser, sendOtp, signOut, UserProvider, useUserDispatch, useUserState, verifyOtpToLogin };

function loginUser(dispatch, phoneNumber, password, history, setIsLoading, setIsError) {
  setIsError(false);
  setIsLoading(true);
  const loginInfo ={
    countryCode: "BD",
    identifier: phoneNumber,
    password: password
  }

  if (!!phoneNumber && !!password) {
    setTimeout(() => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`,loginInfo)
    .then(res=>{
      if (res?.data?.success === true) {
        setIsError(false);
        setIsLoading(false);
        history.push("/otp-confirmation",phoneNumber);
      }
      if(res?.data?.success === false){
        setIsError('1234');
        setIsError(true);
        setIsLoading(false)   
        localStorage.removeItem("bearer-token");
        localStorage.removeItem("user-name");
        localStorage.removeItem("user-specific-name");
        localStorage.removeItem("user-id");
        localStorage.removeItem("user-email");
      }
    })
    .catch(err =>{
        setIsError(true);
        setIsLoading(false)
        localStorage.removeItem("bearer-token");
        localStorage.removeItem("user-name");
        localStorage.removeItem("user-specific-name");
        localStorage.removeItem("user-id");
        localStorage.removeItem("user-email");
    })
  }, 500);
  } else {
    setIsError(true);
    setIsLoading(false)
    dispatch({ type: "LOGIN_FAILURE" });
    
  }
}

function verifyOtpToLogin( setIsLoading, setErrorLoginWithOtp2,otp, phoneNumber, history, dispatch) {
  setErrorLoginWithOtp2(false);
  setIsLoading(true);

  const loginInfo ={
    countryCode: "BD",
    phoneNumber: phoneNumber,
    otp: otp
  }

  if (!!otp && !!phoneNumber) {
    setTimeout(() => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/otp/verify`,loginInfo)
    .then(res=>{
      if (res?.data?.statusCode === 200) {
        localStorage.setItem("permission", JSON.stringify(res?.data?.payload?.listPermissions));
        localStorage.setItem("bearer-token", JSON.stringify(res?.data?.payload?.token));
        localStorage.setItem("user-name", JSON.stringify(res?.data?.payload?.user));
        localStorage.setItem("user-specific-name", JSON.stringify(res?.data?.payload?.user?.fullName));
        localStorage.setItem("user-id", JSON.stringify(res?.data?.payload?.user?.id));
        localStorage.setItem("user-email", JSON.stringify(res?.data?.payload?.user?.email));
        localStorage.setItem("issuer-id", res?.data?.payload?.issuer?.id);
        localStorage.setItem("issuer-name", res?.data?.payload?.issuer?.name);
        localStorage.setItem("issuer-tradecode", res?.data?.payload?.issuer?.tradecode);
        
        setErrorLoginWithOtp2(false);
        setIsLoading(false);
        dispatch({ type: "LOGIN_SUCCESS" });
        history.push("/app/dashboard");
      }
      if(res?.data?.statusCode === 400){
        Swal.fire('wrong otp')
        setIsLoading(false)
        setErrorLoginWithOtp2(true);
       
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
      }

    })
    .catch(err =>{
      setErrorLoginWithOtp2("Entered token is invalid!");
      setIsLoading(false)
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
    })
  }, 500);
  } else {
    setIsLoading(false);
    dispatch({ type: "LOGIN_FAILURE" });
    setErrorLoginWithOtp2("Internal Error!");
    
  }
}

function loginWithOtp(setActiveOtp,setErrorLoginWithOtp,phoneNumber, history,dispatch) {
  const loginInfo ={
    countryCode: "BD",
    phoneNumber: phoneNumber,
    sendSms: true
  }
  if (!!phoneNumber) {
    setTimeout(() => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/otp/send`,loginInfo)
    .then(res=>{
      if (res?.data?.success === true) {
        setActiveOtp(false);
      }
      if(res?.data?.success === false){
        setErrorLoginWithOtp("Username is incorrect!")
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
      }

    })
    .catch(err =>{
      setErrorLoginWithOtp("Username is incorrect!")
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
    })
  }, 500);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });  
  }
}
function sendOtp(setIsErrorResendOtp,phoneNumber) {
  const loginInfo ={
    countryCode: "BD",
    phoneNumber: phoneNumber,
    sendSms: true
  }
  if (!!phoneNumber) {
    setTimeout(() => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/otp/send`,loginInfo)
    .then(res=>{
      if (res?.data?.success === true) {
        setIsErrorResendOtp(false);
      }
      if(res?.data?.success === false){
        setIsErrorResendOtp(true)
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
      }

    })
    .catch(err =>{
      setIsErrorResendOtp(true)
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
    })
  }, 500);
  } else {
    setIsErrorResendOtp(true)
    localStorage.removeItem("bearer-token");
    localStorage.removeItem("user-name");
  }
}

function registrationUser(
          email,
          fullName,
          phoneNumber,
          password,
          nid,
          dob,
          gender,
          setIsLoading,
          setIsError,
          history,
          setIsSuccess,
          setIsErrorMsg
) {
  setIsError(false);
  setIsLoading(true);
     
    const userInfo= {
      "countryCode": "BD",
      "identifier": phoneNumber,
      "firstName": "",
      "lastName": "",
      "fullName": fullName,
      "email": email,
      "password": password,
      "avatar": "image URL",
      "roleType": "Investor",
       "nid" : nid,
       "facebookUrl" : "",
        "instagramUrl":  "",
        "gender": gender,
        "dob": dob,
        "designation": ""
    }

    if (!!email && !!fullName) {
      try {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`,userInfo)
        .then((response) => {
          if (response?.data?.success === true) {
            setIsSuccess(true);
            setIsLoading(false);
            setIsError(false);
            setIsErrorMsg(response?.data?.message);
          } else {
            setIsError(true);
            setIsLoading(false);
            setIsSuccess(false);
            setIsErrorMsg(response?.message)
          }
        })
        .catch((err)=>{
          setIsError(true);
            setIsLoading(false);
            setIsSuccess(false);
            setIsErrorMsg(err?.response?.data?.message)
        });
      } catch (error) {
        setIsErrorMsg('Internal Server Error!');
      }
    } else {
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
      setIsErrorMsg('Internal Server Error!');
    }
}

function forgetPasswordSendOtp(phoneNumber, history, setIsLoading, setIsError,setIsSuccess,setIsErrorMsg) {
  setIsError(false);
  setIsLoading(true);

  const forgetInfo ={
      countryCode: "BD",
      identifier: phoneNumber,
  }

    if (!!phoneNumber) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/forgot-password/otp/send`,forgetInfo)
      .then((response) => {
        if (response?.data?.success === true) {
          setIsLoading(false)
          setIsError(false)
          setIsSuccess(true)
          history.push('forget-password-verify',phoneNumber);
        } else {
          setIsError(true);
          setIsSuccess(false);
          setIsLoading(false);
        }
      })
      .catch((err) =>{
          setIsError(true);
          setIsSuccess(false);
          setIsLoading(false);
          setIsErrorMsg(err?.response?.data?.message)
      })
    } else {
      setIsError(true);
      setIsSuccess(false);
      setIsLoading(false);
    }
}

function forgetPasswordByEmail(optionOTP, identifier, history, setIsLoading, setError, setOptionOTP,) {
  setError(false);
  setIsLoading(true);

  const forgetInfo ={
      countryCode: "BD",
      identifier: identifier,
  }

    if (!!identifier) {
      forgetPassword(forgetInfo).then((response) => {
        if (response.success === true) {
          setOptionOTP(false)
          setIsLoading(false);
          setError(false);
        } else {
          setError(true);
          setIsLoading(false);
        }
      })
      .catch((err) =>{
          setError(true);
          setIsLoading(false);
      })
    } else {
      setError(true);
      setIsLoading(false);
    }
}

function forgetPasswordByOtp( dispatch,otpCode,password, history, setIsLoading, setIsError, phoneNumber ) {

  setIsLoading(true);

  const otpInfo ={
    countryCode: "BD",
    identifier: phoneNumber,
    otp: otpCode,
    password: password,
  }
  if ( !!password && !!otpCode) {

    setTimeout(() => {
      axios.post(`auth/forgot-password/otp/verify`,otpInfo)
    .then(res=>{
      if (res?.data?.success === true) {
        // localStorage.setItem("bearer-token", JSON.stringify(res?.data?.payload?.token));
        // localStorage.setItem("user-name", JSON.stringify(res?.data?.payload?.user));
        // localStorage.setItem("user-specific-name", JSON.stringify(res?.data?.payload?.user?.fullName));
        // localStorage.setItem("user-id", JSON.stringify(res?.data?.payload?.user?.id));
        // localStorage.setItem("user-email", JSON.stringify(res?.data?.payload?.user?.email));
        // setIsLoading(false);
        // dispatch({ type: "LOGIN_SUCCESS" });
        // Swal.fire({
         
        //   text: "Password Reset Successfully",
        //   icon: "success",
        //   showCancelButton: true,
        // });
        Swal.fire({
          title: "Password Reset Successfully",
          confirmButtonText: "Go To Login",
          icon: "success"
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/login");
          } 
        });
        
      }
      if(res?.data?.success === false){
        setIsLoading(false)
        setIsError(true)
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
      }

    })
    .catch(err =>{
      setIsLoading(false)
      setIsError(true)
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
    }) 
    }, 1000);
  } else {
    setIsLoading(false);
    dispatch({ type: "LOGIN_FAILURE" });
    setIsError(true)
  }
}

function forgetPasswordByPhoneNumber(
  phoneNumber,
  setIsLoading,
  setError,
  history,
){

}

function signOut(dispatch, history) {
  localStorage.removeItem("bearer-token");
  localStorage.removeItem("user-name");
  localStorage.removeItem("user-specific-name");
  localStorage.removeItem("user-id");
  localStorage.removeItem("user-email");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
